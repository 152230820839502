import { useState,useEffect } from "react";
import { Heart,Plus,Minus } from 'phosphor-react';
import { useLocation } from 'react-router-dom';
import FullHeart from '../../resources/Icons/FullHeart_Orange.png';
import axios from "axios";
import "./Product.css";

function Product({}) {
  const [mainImg, setMainImg] = useState("");
  const [product, setProduct] = useState({});
  const location = useLocation();
  const variable=decodeURIComponent(location.pathname.replace('/produit/',''));
  const url='https://aroobee-api.azurewebsites.net/Product/GetProductById?Id='.concat(variable)
  const [qte, setQte] = useState(0);

  async function sendAxiosPostRequest(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      // Handle successful response
      return response.data; // Access response data
    } catch (error) {
      // Handle errors
      return null; // Or throw an error if needed
    }
  }
  let cartstoredList = JSON.parse(localStorage.getItem('cartItems')) || [];
  const handleQuantityChange = (event) => {
    let parsed = parseInt(event.target.value);
    if (isNaN(parsed)) { // Check if parsed is NaN
        setQte(0);
    }  
  };
    
  const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url,{
          headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}
        }
        ); // Replace with your API endpoint
        setProduct(response.data);
      }
      catch(err) {
        console.log('there seems to be a problem')
      }
    };
    let cartstoredList = JSON.parse(localStorage.getItem('cartItems')) || [];
    const setQuantityFromCart = () => {
      const index = cartstoredList.findIndex((obj) => obj.id === product.productId);
      if (index !== -1) {
          setQte(cartstoredList[index].newQuantity);
      }
    };
    fetchData();
    if (product) {
      setQuantityFromCart();
    }
  }, [product]);
  function handleAddtoFave() {
    const tokken= sessionStorage.getItem("UserTokken")||false;
    let email= sessionStorage.getItem('userEmail')||false;

    if(tokken){
      const DataToSubmit={
        "productId": product.productId,
        "clientEmail": email,
      }
    sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Product/AddProductToFavorite',DataToSubmit,{
      headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(tokken)}})
    .then(responseData => {
    })
    .catch(error => {
      if(error.response.status===401){
        localStorage.setItem('error', JSON.stringify(error.response.status));
        sessionStorage.clear();
      }
    });
    }      
  }
  function handleDeletetoFave() {
    const tokken= sessionStorage.getItem("UserTokken")||false;
    let email= sessionStorage.getItem('userEmail')||false;

    if(tokken){
      const DataToSubmit={
        "productId": product.productId,
        "clientEmail": email
      }
    sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Product/DeleteProductToFavorite',DataToSubmit,{headers: {"Access-Control-Allow-Origin": "*","Authorization":
    "Bearer ".concat(tokken)}})
    .then(responseData => {

    })
    .catch(error => {
      if(error.response.status===401){
        localStorage.setItem('error', JSON.stringify(error.response.status));
        sessionStorage.clear();
      }
    });
    }
  }

  function replaceObjectWithId(list, id, newObject) {
    if (!list || !newObject) {
      throw new Error('Invalid arguments: list and newObject are required.');
    }
    let index = list.findIndex(obj => obj.id === id);
    if (index === -1) {
      list=[...list, newObject]
    }else{
      list[index] = newObject;
    }      
    return list; // Return the updated list
  }
      function renameColumns(data, renameMap) {
        return data.map(item => {
          const newItem = {};
          for (const key in item) {
            const newKey = renameMap[key] || key; // Use original key if not mapped
            newItem[newKey] = item[key];
          }
          return newItem;
        });
      }
      function handleAddQteChange() {
        const newQuantity=qte+1;
        if(newQuantity<=product.productMaxQuantity){
          let myObject ={ "id":product.productId,"img":product.productImg, "title":product.productName, "newPrice":product.productNewPrice, "newQuantity":newQuantity,"MaxQuantity":product.productMaxQuantity };
          let cartList = JSON.parse(localStorage.getItem('cartItems')) || [];
          cartList=replaceObjectWithId(cartList,product.productId,myObject);
          let updatedCartItems=renameColumns(cartList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
          const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
          const DataToSend={
          "cartId": 0,
          "clientMail": "string",
          "subTotal": 0,
          "total": 0,
          "cartItems": updatedCartItems
      }
      if(Token){
          sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
          {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
          .then(response => {
          })}
          localStorage.setItem('cartItems', JSON.stringify(cartList));
          setQte(qte+1);
        }else{
            setQte(qte);
        }
    }
    function handleMinQteChange() {
        const newQuantity=qte-1;
          if(qte>0){
            let myObject ={ "id":product.productId,"img":product.productImg, "title":product.productName, "newPrice":product.productNewPrice, "newQuantity":newQuantity,"MaxQuantity":product.productMaxQuantity };
            cartstoredList=replaceObjectWithId(cartstoredList,product.productId,myObject);
            let updatedCartItems=renameColumns(cartstoredList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
          const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
          const DataToSend={
          "cartId": 0,
          "clientMail": "string",
          "subTotal": 0,
          "total": 0,
          "cartItems": updatedCartItems
      }   
      if(Token){      
          sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
          {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
          .then(response => {
          })  
        }
            setQte(qte-1);
          }
          if(newQuantity===0){
            let myObject ={ "id":product.productId,"img":product.productImg, "title":product.productName, "newPrice":product.productNewPrice, "newQuantity":newQuantity,"MaxQuantity":product.productMaxQuantity };
            cartstoredList=replaceObjectWithId(cartstoredList,product.productId,myObject);
            let updatedCartItems=renameColumns(cartstoredList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
          const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
          const DataToSend={
          "cartId": 0,
          "clientMail": "string",
          "subTotal": 0,
          "total": 0,
          "cartItems": updatedCartItems
      }
      if(Token){
          sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
          {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
          .then(response => {
            cartstoredList=cartstoredList.filter(obj => obj.id !== product.productId);
            localStorage.setItem('cartItems', JSON.stringify(cartstoredList));


          })}
          }
          localStorage.setItem('cartItems', JSON.stringify(cartstoredList));
        }
        // console.log(product)
    return (
    <div className="Product_Page_Container">
        <div className="product_Container">
        {product.imagesUrl && (

            <div className="product_images">
              {mainImg?
                <img className="main_image" src={mainImg} alt={mainImg}/>:<img className="main_image" src={product.imagesUrl[0].photoUrl} alt={product.imagesUrl[0].photoUrl}/>
                }
                {product.imagesUrl[0] && (<img className={product.imagesUrl[0].photoUrl===mainImg?"selected_other_image3":"other_image3"} alt={product.imagesUrl[0].photoUrl} src={product.imagesUrl[0].photoUrl} onClick={() => setMainImg(product.imagesUrl[0].photoUrl)} />)}
                {product.imagesUrl[1] && (<img className={product.imagesUrl[1].photoUrl===mainImg?"selected_other_image2":"other_image2"} alt={product.imagesUrl[1].photoUrl} src={product.imagesUrl[1].photoUrl} onClick={() => setMainImg(product.imagesUrl[1].photoUrl)} />)}
                {product.imagesUrl[2] && (<img className={product.imagesUrl[2].photoUrl===mainImg?"selected_other_image1":"other_image1"} alt={product.imagesUrl[2].photoUrl} src={product.imagesUrl[2].photoUrl} onClick={() => setMainImg(product.imagesUrl[2].photoUrl)} />)}
            </div>)}
            <div className="Product_Price">
                <p className="prod_name">{product.productName}</p>
                <p className="product-brand"><b >{product.productMarque}</b></p>
                <div className="price-cont"><b>${product.productNewPrice?.toFixed(2)}</b>{product.productPrevPrice? <del> ${product.productPrevPrice?.toFixed(2)}</del>:null}</div>
                <div className="priceLabel">{product.productPriceLabel}</div> 
                <p className="stock">Encore {product.productMaxQuantity-qte} exemplaires en stock</p>
                {qte>0&&<div className="quantity">
                    <label for="quantity"><b>Quantité :</b></label>
                    <button className='HeartButton' onClick={handleMinQteChange}><Minus size={32} color="#F08F02" /></button>
                    <input type="number" id="quantity" name="quantity" value={qte} min="1" max="10"onChange={handleQuantityChange}/>
                    <button className='HeartButton' onClick={handleAddQteChange}><Plus size={32} color="#F08F02"  /></button>
                    {product.isFavorite?
                    <button className='HeartButton ' onClick={handleDeletetoFave}><img className="FullHeart"src={FullHeart} alt="PackageIcon"/></button>:
                    <button className='HeartButton' onClick={handleAddtoFave}><Heart  size={32} color="#F08F02" /></button>
                  }
                </div>}
                {qte===0&&<div className='Buttonsfave'>
                <button className="add-to-cart" onClick={handleAddQteChange}>Ajouter au panier</button>  {product.isFavorite?
                    <button className='HeartButton ' onClick={handleDeletetoFave}><img className="FullHeart" src={FullHeart} alt="PackageIcon"/></button>:
                    <button className='HeartButton' onClick={handleAddtoFave}><Heart  size={32} color="#F08F02" /></button>
                  }
                </div>}                
            </div>
            <div className="product_details">
                <div className="textbox">
                    <h2>Description</h2>
                    <hr/>
                    {/* <p>{product.productdesciprion}</p> */}
                    <p><b>Origine :</b> {product.productpays}</p>
                    <br />
                </div>
                {product.productIngredients&& 
                <div className="textbox">
                    <h2>Ingrédients</h2>
                    <hr/>
                    <p>{product.productIngredients}</p>
                </div>}
                {/* <div className="textbox">
                    <h2>Informations nutrionnelles</h2>
                    <hr/>
                    <div className='Table'>
                        <table>
                            <tr>
                                <th>Informations nutrionnelles</th>
                                <th>Pour 100g</th>
                            </tr>
                            <tr>
                                <td>Informations nutrionnelles</td>
                                <td>100g</td>
                            </tr>
                            <tr>
                                <td>Informations nutrionnelles</td>
                                <td>100g</td>
                            </tr>
                        </table>
                    </div>
                </div> */}
                </div>
            </div>
    </div>
    );
    };
export default Product;