import "./Sidebar.css";
import React from 'react';
import { XCircle } from "phosphor-react";
import SidebarMarque from "./SidebarMarque";
import SidebarOrigine from "./SidebarOrigine";
import SidebarPrice from "./SidebarPrice";
const Sidebar = ({ buttonOn,setButtonOn,handleChangePays,handleChangeMarque,handleChangePrix }) => {
    const handleClick = () => {
        setButtonOn(!buttonOn)

    }
    return (
<div className={buttonOn ?"sidebar sidebar-full":"sidebar"}>
    <div className={buttonOn ?'sidebar-container-on' : 'sidebar-container-off' }>
        <button onClick={handleClick}><XCircle size={32} /></button>

        <div className="sidebar-selector-container">
        <div>
        <SidebarMarque handleChangeMarque={handleChangeMarque}/>
        </div>
        <div>
        <SidebarOrigine handleChangePays={handleChangePays}/>
        </div>
        <div>
        <SidebarPrice handleChangePrix={handleChangePrix} />
        </div>
        </div>
    </div>
</div>);
};

export default Sidebar;