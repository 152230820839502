import ProductComponent from "../MultiplePageComponents/productComponent";
import "./ProductExposition.css";

const ProductsExposition = ({ buttonOn,result }) => {

  return (
    <>
      <section className={buttonOn?"card_container_hidden card-container":"card-container"}>
        {result &&
          // quantityVals &&
          result.map(
            ({
              productId,
              productImg,
              productName,
              productMarque,
              productPrevPrice,
              productNewPrice,
              productMaxQuantity,
              productQuantity,
              isFavorite,
              productPriceLabel
            }) => (
              <ProductComponent
                id={productId}
                img={productImg}
                title={productName}
                marque={productMarque}
                prevPrice={productPrevPrice}
                newPrice={productNewPrice}
                MaxQuantity={productMaxQuantity}
                currentQuantity={productQuantity}
                isFavorite={isFavorite}
                productPriceLabel={productPriceLabel}
                // handleMinQteChange={handleMinQteChange}
                // handleAddQteChange={handleAddQteChange}
              />
            )
          )}
      </section>
    </>
  );
};

export default ProductsExposition;