import "./DesertSection.css";
import DessertImg from '../../resources/image 4.jpg';
import productImg from '../../resources/Zgougou.png';

import { ArrowRight } from 'phosphor-react';

function DesertSection ({}) {
    return (
        <div className="desertsection">
            <div className="deserth">
                <h1 className="h1dessert">Des desserts</h1>
                <h2 className="h2dessert">RÉCONFORTANT</h2>
            </div>
           <div className="desertimage_box">
                <img className="imgdessert" src={DessertImg} alt="A picture of a dessert in a glass" />
                <section className="desert-yellow-container">
                </section>
                <section className="desert-text-container">
                    <h6>Desserts authentiques</h6>
                    <p>Un régal, découvrez notre sélection de produits d'épicerie sucrée livrés jusqu'à chez-vous.</p>
                    <a href={"/Exposition/Épicerie sucrée"}>DÉCOUVRIR <ArrowRight size={25} /></a>
                </section>
                <section className="product-container">
                    <a href={"/produit/274"}>
                        <img className="productimg" src={productImg} alt="A picture of a dessert in a glass" />
                        <div className="producttext">                    
                            <a>Confiture abricot, 250g </a>
                            <p>Sicam</p>
                        </div>
                    </a>                    
                </section>
            </div>     
        </div>
    )
}
export default DesertSection;