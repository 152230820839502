import "./SidebarProfil.css";
import { useLocation } from "react-router-dom";

const Sidebar = ({  }) => {
    const location = useLocation();
  const variable = decodeURIComponent(
    location.pathname.replace("/Profil/", "")
  );
    return (
<div className="sidebar sidebar_profil">
    <b> Mon Compte    </b><hr />
    <ul>
        {variable=="Mesinformations"?<li ><a className="selected_a_sidebarprofil" href="/Profil/Mesinformations">Mes informations</a></li>:<li ><a href="/Profil/Mesinformations">Mes informations</a></li>}
        {variable=="Mesadresses"?<li ><a className="selected_a_sidebarprofil" href="/Profil/Mesadresses">Mes adresses</a></li>:<li ><a href="/Profil/Mesadresses">Mes adresses</a></li>}
        {variable=="Mescommandes"?<li ><a className="selected_a_sidebarprofil" href="/Profil/Mescommandes">Mes commandes</a></li>:<li ><a  href="/Profil/Mescommandes">Mes commandes</a></li>}
        {/* <li><a href="/Profil/Méthodesdepaiement">Méthodes de paiement</a></li> */}
        {variable=="Malisteenvie"?<li ><a className="selected_a_sidebarprofil" href="/Profil/Malisteenvie">Ma liste d'envie</a></li>:<li ><a href="/Profil/Malisteenvie">Ma liste d'envie</a></li>}
        {variable=="ModifierMDP"?<li ><a className="selected_a_sidebarprofil" href="/Profil/ModifierMDP">Modifier le mot de passe</a></li>:<li ><a href="/Profil/ModifierMDP">Modifier le mot de passe</a></li>}
        {variable=="Déconnecter"?<li ><a className="selected_a_sidebarprofil" href="/Déconnecter">Me déconnecter</a></li>:<li ><a href="/Déconnecter">Me déconnecter</a></li>}
    </ul>
    <div className="InfoLegal">
    <b> Informations légales</b>
    <hr />
    <ul>
        {variable=="Politiqueconfidentialité"?<li ><a className="selected_a_sidebarprofil"  href="/Profil/Politiqueconfidentialité">Politique de confidentialité </a></li>:<li ><a href="/Profil/Politiqueconfidentialité">Politique de confidentialité </a></li>}
        {variable=="Conditionsutilisation"?<li ><a className="selected_a_sidebarprofil" href="/Profil/Conditionsutilisation">Conditions d'utilisation</a></li>:<li ><a href="/Profil/Conditionsutilisation">Conditions d'utilisation</a></li>}
        {variable=="Mentionslégales"?<li ><a className="selected_a_sidebarprofil"  href="/Profil/Mentionslégales">Mentions légales</a></li>:<li ><a  href="/Profil/Mentionslégales">Mentions légales</a></li>}
        </ul>
        </div>
</div>);
};

export default Sidebar;