import "./SidebarMarque.css";
import { useLocation } from 'react-router-dom';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
const SidebarMarque = ({handleChangeMarque  }) => {
  const location = useLocation();
  let variable=decodeURIComponent(location.pathname.replace('/Exposition/',''));
    const [Marquedata, setMarquedata] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try{

          const response = await axios.get('https://aroobee-api.azurewebsites.net/Product/GetBrandsByCategory?Category='.concat(variable),{
          headers: {"Access-Control-Allow-Origin": "*"}}
        ); // Replace with your API endpoint
        setMarquedata(response.data);
        }catch(err){
          }
        };
    
        fetchData();
      }, []);
    return (
        <div className="SidebarChanger">
            <div>
            <h2 className="sidebar-title color-title">MARQUE</h2>

            </div>
            {Marquedata.map((Marque) => (
                            <div>

                 <label className="sidebar-label-container">
                 <input  name="Marque test"  type="checkbox" value={Marque.brandName}  onChange={handleChangeMarque}/>
                 <span className="checkmark all"> </span>
                  {Marque.brandName}
                 </label>
                 </div>
            ))}

        </div>
        );
};

export default SidebarMarque;