import "./RecapCommance.css";
import{ useEffect,useState } from "react";
import TickIcon from '../../resources/tick.png';
import axios from "axios";


function RecapCommance({}) {
    const monthNames = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];
      function formatDate(dateString) {
        let ds="1 juin 2024 à 09:02"
        if (typeof dateString === 'string' || dateString instanceof String){
            ds=dateString}

        // 1. Parse the date string into a Date object
        const parts = ds.split(" "); // Split by spaces
        const day = parseInt(parts[0]); // Get day (remove trailing "e")
        const month = parts[1]; // Get month
        const year = parseInt(parts[2]); // Get year
      
        const date = new Date(year, monthNames.indexOf(month), day);
      
        // 2. Add 2 days to the date
        date.setDate(date.getDate() + 2);
      
        // 3. Format the date as "d Month" (e.g., "4 Juin")
        const formattedDate = new Intl.DateTimeFormat("fr-FR", {
          day: "numeric",
          month: "long",
        }).format(date);
        return formattedDate 
      }
    async function sendAxiosPostRequest(url, data, config = {}) {
        try {
          const response = await axios.post(url, data, config);
          // Handle successful response
          return response.data; // Access response data
        } catch (error) {
          // Handle errors
          return null; // Or throw an error if needed
        }
      }
      const [orderInfo, setOrderInfo] = useState({});
      const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
      useEffect(() => {
        // Fetch data only once on component mount
        const fetchData = async () => {
          try {
            const response = await sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Order/GetLastOrder', {}, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${Token}`, // Use template literal for better readability
              },
            });
            setOrderInfo(response);
          } catch (err) {
            if(err.response.status===401){
              localStorage.setItem('error', JSON.stringify(err.response.status));
              sessionStorage.clear();
            } // Use console.error for error messages
          } finally {
            // Set orderInfo.filled to true regardless of success or failure
            setOrderInfo(prevOrderInfo => ({ ...prevOrderInfo, filled: true }));
          }
        };
      
        fetchData();
        // Clean up: Remove any potential memory leaks (if applicable)
        return () => {
          // Add cleanup logic here if necessary
        };
      }, []); // Empty dependency array to run only on mount
        return (
            <div className="RecapCommanceContainer">
                <div className="recapcircle">       
                     <img className='phosphoreIcon' src={TickIcon} alt="TickIcon" />
                </div>
                <div>Commande validé</div>
                <div>Livraison estimée le <b>{formatDate(orderInfo?.orderDate)}</b></div>
                <div>Assurez- vous d'être présent au moment de la livraison.</div>
                <a href="/">Retournez à la page d'acceuil</a>
            </div>
            );
    };
export default RecapCommance;