import "./HomeEventSection.css";
import EventImg1 from '../../resources/event-image.png';
import EventImg2 from '../../resources/slider-2.png';
import EventImg3 from '../../resources/slider-3.png';

function HomeEventSection ({}) {
    return (
        <div>
            <div className="Event-section">
            
         </div>
            <div id="demo" class="carousel slide" data-bs-ride="carousel">

            <div class="carousel-indicators">
                <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
            </div>
            
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <section class="spice-selection">
                        <div class="content">
                                <h2>Notre sélection</h2>
                                <h2 className="event-name">Épicerie salée</h2>                                 
                                <p>Retrouvez le vrai goût de la cuisine méditerranéenne grâce à notre sélection de produits authentiques.</p>
                                <div className="ContentButton">
                                    <a href={"/Exposition/Épicerie%20salée"}>Notre sélection</a>
                                </div>
                            </div>
                            <img src={EventImg1} alt="A selection of spices on a table"/>
                            
                </section>
                </div>
                <div class="carousel-item">
                <section class="spice-selection">
                        <div class="content">
                                <h2>Notre sélection</h2>
                                <h2 className="event-name">Les épices</h2>
                                <p>Donnez du goût à votre cuisine. Nous avons sélectionné pour vous les meilleurs épices maison.</p>
                                <div className="ContentButton">
                                    <a href={"/Exposition/Herbes%20et%20épices"}>Notre sélection</a>
                                </div>
                            </div>
                            <img src={EventImg2} alt="A selection of spices on a table"/>
                            
                </section>
                </div>
                <div class="carousel-item">
                <section class="spice-selection">
                        <div class="content">
                                <h2>Notre sélection</h2>
                                <h2 className="event-name">Eaux florales</h2>
                                <p>Ressourcez-vous grâce à nos eaux florales faites à l'ancienne :<br/>eau de rose, eau de fleur de bigaradier et eau florale de géranium</p>
                                <div className="ContentButton">
                                    <a href={"/Exposition/Assaisonnement"}>Notre sélection</a>
                                </div>
                            </div>
                            <img src={EventImg3} alt="A selection of spices on a table"/>
                            
                </section> 
                </div>
            </div>
            
            <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
            </button>
            </div>
        </div>
        

  )
}
export default HomeEventSection;