import "./ValidationCommande.css";
import { Lock } from "phosphor-react"; 
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import{ useEffect,useState } from "react";

function ValidationCommande({}) {
    async function sendAxiosPostRequest(url, data, config = {}) {
        try {
          const response = await axios.post(url, data, config);
          // Handle successful response
          return response.data; // Access response data
        } catch (error) {
          // Handle errors
          return null; // Or throw an error if needed
        }
      }
      const [orderInfo, setOrderInfo] = useState({});
      const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
      useEffect(() => {
        // Fetch data only once on component mount
        const fetchData = async () => {
          try {
            const response = await sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Order/GetLastOrder', {}, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${Token}`, // Use template literal for better readability
              },
            });
            setOrderInfo(response);
          } catch (err) {
            if(err.response.status===401){
              localStorage.setItem('error', JSON.stringify(err.response.status));
              sessionStorage.clear();
            } // Use console.error for error messages
          } finally {
            // Set orderInfo.filled to true regardless of success or failure
            setOrderInfo(prevOrderInfo => ({ ...prevOrderInfo, filled: true }));
          }
        };
      
        fetchData();
        // Clean up: Remove any potential memory leaks (if applicable)
        return () => {
          // Add cleanup logic here if necessary
        };
      }, []); // Empty dependency array to run only on mount
    const navigate = useNavigate();

    function RetourUserInfor(){

      navigate("/Commande/UserInfo");
    }
    function RetourPaiement(){

        navigate("/Commande/paiement");
      }
      const monthNames = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];
      function formatDate(dateString) {
        let ds="1 juin 2024 à 09:02"
        if (typeof dateString === 'string' || dateString instanceof String){
            ds=dateString}

        // 1. Parse the date string into a Date object
        const parts = ds.split(" "); // Split by spaces
        const day = parseInt(parts[0]); // Get day (remove trailing "e")
        const month = parts[1]; // Get month
        const year = parseInt(parts[2]); // Get year
      
        const date = new Date(year, monthNames.indexOf(month), day);
      
        // 2. Add 2 days to the date
        date.setDate(date.getDate() + 2);
      
        // 3. Format the date as "d Month" (e.g., "4 Juin")
        const formattedDate = new Intl.DateTimeFormat("fr-FR", {
          day: "numeric",
          month: "long",
        }).format(date);
        return formattedDate 
      }
      const handleConfirmSubmit = (e) => {
        sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Order/ConfirmOrder',{},{
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${Token}`, // Use template literal for better readability
          },
        })
            .then(responseData => {
              navigate("/Commande/RecapCommance");

              
            })
      }
      // Define month names for parsing (assuming French locale)
      
        return (
            <div className="ValitationCommandePage">
            <div className="ValidationCommandeContainer">
                <div className="ValidationMenu">
                    <div className="ValidationMenuContainer">
                    <div className="ValidationMenuTitle"><Lock size={22} /> <b>Validation de votre commande</b></div>
                    <div className="ValidationOptionsContainer">
                        <div className="ValidationOptionContainer">
                            <div className="OptionName">Adresse de livraison</div> 
                            <div className="optionBox">
                            <div className="OptionValue">
                            <div>{orderInfo?.deliverAddress?.firstName} {orderInfo?.deliverAddress?.lastName}</div>
                            <div>{orderInfo?.deliverAddress?.addressName}</div>
                            <div>{orderInfo?.deliverAddress?.zipCode} {orderInfo?.deliverAddress?.city}</div>
                            <div> Québec</div></div>
                            <button onClick={RetourUserInfor}>Modifier</button>
                            </div>
                        </div>
                        <div className="SeperateLine"> </div>

                        <div className="ValidationOptionContainer">
                            <div className="OptionName">Adresse de facturation</div> 
                            <div className="optionBox">
                            {orderInfo?.deliverAddress?.addressId===orderInfo?.facturationAddress?.addressId?
                            <div className="OptionValue">Identique à l'adresse de livraison</div>:
                            <div className="OptionValue">
                                <div>{orderInfo?.facturationAddress?.firstName} {orderInfo?.facturationAddress?.lastName}</div>
                            <div>{orderInfo?.facturationAddress?.addressName}</div>
                            <div>{orderInfo?.facturationAddress?.zipCode} {orderInfo?.facturationAddress?.city}</div>
                            <div> Québec</div></div>
                         }

                            
                            <button  onClick={RetourUserInfor}>Modifier</button>
                            </div>
                        </div>
                        <div className="SeperateLine"> </div>
                        
                        <div className="ValidationOptionContainer">
                            <div className="OptionName">Mode de paiement</div> 
                            <div className="optionBox">

                            <div className="OptionValue"><div>{orderInfo.payementType===1?"Interac":"Argent liquide"}</div></div>
                            <button   onClick={RetourPaiement}>Modifier</button>
                            </div>
                        </div>
                        <div className="SeperateLine"> </div>

                        <div className="ValidationOptionContainer">
                            <div className="OptionName">Expédition</div> 
                            <div className="optionBox">
                            <div className="OptionValue">Livraison estimée le {formatDate(orderInfo?.orderDate)}</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ValidationSummaryContainer">
                    <div className="ValidationSummaryContent">
                        <div className="ValidationSummaryContainerTop">
                            <div className="SummaryItem"><b>Récapitulatif de la commande :</b></div>
                            <div className="SummaryItem"><div>Sous-tolal</div><div>${orderInfo?.orderSubTotal?.toFixed(2)}</div></div>
                            <div className="SummaryItem"><div>Emballage et expédition</div><div>$7.00</div></div>
                            <div className="SummaryItem"><div>Pourboire livreur</div><div>${orderInfo?.orderPourboir?.toFixed(2)}</div></div>
                        </div>
                        <div className="ValidationSummaryContainerBottom">
                            <div  className="SummaryItem"><div><b>Montant total : </b></div><div><b>${orderInfo?.orderTotal?.toFixed(2)}</b></div></div>
                            <div  className="SummaryItem">TPS incluse : ${orderInfo?.orderTPS?.toFixed(2)}</div>
                            <div  className="SummaryItem"> TVQ incluse : ${orderInfo?.orderTVQ?.toFixed(2)}</div>
                            <button className="" onClick={handleConfirmSubmit}>Confirmer le paiement</button>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>
            );
    };

export default  ValidationCommande;