import React, { useState,useEffect } from 'react';
import { Heart,Plus,Minus, ShoppingBagOpen } from 'phosphor-react';
import axios from 'axios';

import "./ProductFavComponent.css";
function ProductFavComponent ({ id,img,marque, title, prevPrice, newPrice,MaxQuantity,productPriceLabel }) {
  const [quantity, setQuantity] = useState(0);
  let cartstoredList = JSON.parse(localStorage.getItem('cartItems')) || [];
  const handleQuantityChange = (event) => {
    let parsed = parseInt(event.target.value);
    if (isNaN(parsed)) { // Check if parsed is NaN
      setQuantity(0);
  }
  
  };
  useEffect(() => {
    let cartstoredList = JSON.parse(localStorage.getItem('cartItems')) || [];
    const setQuantityFromCart = () => {
      const index = cartstoredList.findIndex((obj) => obj.id === id);
      if (index !== -1) {
        setQuantity(cartstoredList[index].newQuantity);
      }
    };

    setQuantityFromCart(); // Call the function on component load
  }, []);
  function renameColumns(data, renameMap) {
    return data.map(item => {
      const newItem = {};
      for (const key in item) {
        const newKey = renameMap[key] || key; // Use original key if not mapped
        newItem[newKey] = item[key];
      }
      return newItem;
    });
  }
  function replaceObjectWithId(list, id, newObject) {
    if (!list || !newObject) {
      throw new Error('Invalid arguments: list and newObject are required.');
    }
    let index = list.findIndex(obj => obj.id === id);
    if (index === -1) {
      list=[...list, newObject]
    }else{
      list[index] = newObject;
    }
    
    return list; // Return the updated list
  }
  function handleAddQteChange() {
    const newQuantity=quantity+1;
    if(newQuantity<=MaxQuantity){
      let myObject ={ "id":id,"img":img, "title":title, "newPrice":newPrice, "newQuantity":newQuantity,"MaxQuantity":MaxQuantity };
      let cartList = JSON.parse(localStorage.getItem('cartItems')) || [];
      cartList=replaceObjectWithId(cartList,id,myObject);
      let updatedCartItems=renameColumns(cartList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
          const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
          const DataToSend={
          "cartId": 0,
          "clientMail": "string",
          "subTotal": 0,
          "total": 0,
          "cartItems": updatedCartItems
      }
          sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
          {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
          .then(response => {
          })
      localStorage.setItem('cartItems', JSON.stringify(cartList));
      setQuantity(quantity+1);
    }else{
      setQuantity(quantity);
    }
}
function handleMinQteChange() {
  const newQuantity=quantity-1;
    if(quantity>0){
      let myObject ={}
      myObject ={ "id":id,"img":img, "title":title, "newPrice":newPrice, "newQuantity":newQuantity };
      cartstoredList=replaceObjectWithId(cartstoredList,id,myObject);
      let updatedCartItems=renameColumns(cartstoredList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
          const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
          const DataToSend={
          "cartId": 0,
          "clientMail": "string",
          "subTotal": 0,
          "total": 0,
          "cartItems": updatedCartItems
      }
          sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
          {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
          .then(response => {
          })
      setQuantity(quantity-1);
    }
    if(newQuantity===0){
      let myObject ={}
      myObject ={ "id":id,"img":img, "title":title, "newPrice":newPrice, "newQuantity":newQuantity };
      cartstoredList=replaceObjectWithId(cartstoredList,id,myObject);
      let updatedCartItems=renameColumns(cartstoredList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
          const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
          const DataToSend={
          "cartId": 0,
          "clientMail": "string",
          "subTotal": 0,
          "total": 0,
          "cartItems": updatedCartItems
      }
          sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
          {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
          .then(response => {
          })
      cartstoredList=cartstoredList.filter(obj => obj.id !== id);
    }
    localStorage.setItem('cartItems', JSON.stringify(cartstoredList));
  }
  async function sendAxiosPostRequest(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      // Handle successful response
      return response.data; // Access response data
    } catch (error) {
      // Handle errors
      return null; // Or throw an error if needed
    }
  }
  function handleRemovetoFave()
  {
    const email="faycelsassi@gmail.com";
    const DataToSubmit={
        "productId": id,
        "clientEmail": email
      }
    sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Product/DeleteProductToFavorite',DataToSubmit,{headers: {"Access-Control-Allow-Origin": "*"}})
    .then(responseData => {
        window.location.reload(true);
    })
    .catch(error => {
        // Handle errors
    });
}

  return (
    <>
      <div className="productfave-card">
      <a href={"/produit/"+id}>
        <img src={img} alt={title} className="card-img" /></a>
        <div className="card-details">
        <a href={"/produit/"+id}>
            <div className='child-left'>
              <p>{title}</p>
              <h3 className="card-title"><b>{marque}</b></h3>
              <div className="card-price">
              <div className="">
              {prevPrice === 0 ? <span className='prevPrice'>${newPrice}</span> : <> <span className='newPrice'>${newPrice}</span> <del>${prevPrice}</del></>}
              </div>
              </div>
              <div className='priceLabel'>{productPriceLabel}</div>

            </div>
            </a>
            <div className="child-right">
              <div className="card-actions">
                <div>
                <button className='HeartButton' onClick={handleRemovetoFave}><Heart size={18} /></button>
              </div>
              {quantity > 0 ? (
                <div className="quantity-wrapper">
                    <div>
                    <button className='HeartButton' onClick={handleAddQteChange}><Plus size={18} /></button>
                    </div>
                    <div>
                      <input
                          id="quantity"
                          type="number"
                          min="0"
                          max={MaxQuantity}
                          value={quantity}
                          onChange={handleQuantityChange}
                          className="quantity-input"
                          readOnly
                      />
                      </div>
                      <div>
                      <button className='HeartButton' onClick={handleMinQteChange}><Minus size={18} /></button>
                      </div>
                      </div>
              ) :      <button className='HeartButton'onClick={handleAddQteChange}><ShoppingBagOpen size={18} /></button>
            }

                
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductFavComponent;