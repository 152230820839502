import "./Login.css";
import React, { useState } from "react";
import { Eye } from "phosphor-react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

function Login(maniPage) {
  const navigate = useNavigate();
  const [loginFormValues, setLoginformValues] = useState({
    email: "",
    password: "",
  });
  const [loginFormErrors, setloginFormErrors] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  async function sendAxiosPostRequest(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      // Handle successful response
      return response.data; // Access response data
    } catch (error) {
      // Handle errors
      return null; // Or throw an error if needed
    }
  }

  function renameColumns(data, renameMap) {
    return data.map((item) => {
      const newItem = {};
      for (const key in item) {
        const newKey = renameMap[key] || key; // Use original key if not mapped
        newItem[newKey] = item[key];
      }
      return newItem;
    });
  }
  const PWD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\-^&*()_+])[A-Za-z0-9!@#$%^&\-*()_+]{8,20}$/;
  const handleInputChange = (e) => {
    setLoginformValues({ ...loginFormValues, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {};

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginFormValues.email)) {
      newFormErrors.email = "E-mail invalide";
    }
    if (!PWD_REGEX.test(loginFormValues.password)) {
      newFormErrors.password = "Mot de passe invalide";
    }

    setloginFormErrors(newFormErrors);

    if (Object.keys(newFormErrors).length === 0) {
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (cartItems != []) {
        let updatedCartItems = renameColumns(cartItems, {
          id: "productId",
          newQuantity: "quantity",
          img: "photos",
          title: "productName",
          productPrice: "newPrice",
        });
        sendAxiosPostRequest(
          "https://aroobee-api.azurewebsites.net/Login/ClientLogin",
          loginFormValues,
          { headers: { "Access-Control-Allow-Origin": "*" } }
        ).then((responseData) => {
          const DataToSend = {
            cartId: 0,
            clientMail: "string",
            subTotal: 0,
            total: 0,
            cartItems: updatedCartItems,
          };
          sendAxiosPostRequest(
            "https://aroobee-api.azurewebsites.net/Cart/AddCart",
            DataToSend,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer ".concat(responseData.token),
              },
            }
          )
            .then((response) => {
              return axios.get(
                "https://aroobee-api.azurewebsites.net/Cart/GetCart",
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer ".concat(responseData.token),
                  },
                }
              );
            })
            .then((cartResponse) => {
              let updatedCartItems = renameColumns(
                cartResponse.data.cartItems,
                {
                  productId: "id",
                  quantity: "newQuantity",
                  photos: "img",
                  productName: "title",
                  productPrice: "newPrice",
                }
              );
              localStorage.removeItem("cartItems");
              localStorage.setItem(
                "cartItems",
                JSON.stringify(updatedCartItems)
              );
            });
          // Set data in session storage
          sessionStorage.setItem(
            "userEmail",
            JSON.stringify(responseData.email)
          );
          sessionStorage.setItem(
            "userName",
            JSON.stringify(
              responseData.firstName.concat(" ", responseData.lastName)
            )
          );
          sessionStorage.setItem(
            "UserTokken",
            JSON.stringify(responseData.token)
          );
          navigate(maniPage.maniPage);
        });
      }
    }
  };
  return (
    <div className="login_container">
      <h2>Identifiez-vous</h2>

      <div className="full_login_container">
        <div className="Inscription_box">
          <h3>Je suis nouveau client</h3>
          <a href="/inscription">
            <button className="button">Créer un compte</button>
          </a>
          <p>Avantages d'un compte client :</p>
          <ul>
            <li>Je suis le statut de ma commande en cours</li>
            <li>J'accède à l'historique de mes commandes</li>
            <li>Je crée et j'actualise mes listes d'envies</li>
            <li>Je gère mes adresses de livraisons</li>
          </ul>
        </div>

        <form className="Login_Box" onSubmit={handleSubmit}>
          <h3>J'ai déjà un compte</h3>
          <div className="/action_page.php">
            <input
              type="email"
              value={loginFormValues.email}
              onChange={handleInputChange}
              id="email"
              name="email"
              placeholder="E-mail"
            />
            <br />
            {loginFormErrors.email && <p>{loginFormErrors.email}</p>}
            <div className="input-container">
              <input
                className="eyeinput"
                type={passwordShown ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Mot de passe"
                value={loginFormValues.password}
                onChange={handleInputChange}
              />
              <Eye
                className="phosphoreIcon"
                size={32}
                onClick={(e) => {
                  setPasswordShown(!passwordShown);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <br />
            </div>
            <div>
              {loginFormErrors.password && <p>{loginFormErrors.password}</p>}
            </div>
            <a href="#">Mot de passe oublié ?</a>
            <br />

            <button type="submit" className="button">
              Se connecter
            </button>
          </div>
        </form>
      </div>
      <div className="small_login_container">
        <form className="Login_Box" onSubmit={handleSubmit}>
          <h3>J'ai déjà un compte</h3>
          <div className="/action_page.php">
            <input
              type="email"
              value={loginFormValues.email}
              onChange={handleInputChange}
              id="email"
              name="email"
              placeholder="E-mail"
            />
            <br />
            {loginFormErrors.email && <p>{loginFormErrors.email}</p>}
            <div className="input-container">
              <input
                className="eyeinput"
                type={passwordShown ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Mot de passe"
                value={loginFormValues.password}
                onChange={handleInputChange}
              />
              <Eye
                className="phosphoreIcon"
                size={32}
                onClick={(e) => {
                  setPasswordShown(!passwordShown);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
              <br />
            </div>
            <div>
              {loginFormErrors.password && <p>{loginFormErrors.password}</p>}
            </div>
            <a href="#">Mot de passe oublié ?</a>
            <br />

            <button type="submit" className="button">
              Se connecter
            </button>
          </div>
        </form>
        <div className="Inscription_box">
          <h3>Je suis nouveau client</h3>
          <a href="/inscription">
            <button className="button">Créer un compte</button>
          </a>
          <p>Avantages d'un compte client :</p>
          <ul>
            <li>Je suis le statut de ma commande en cours</li>
            <li>J'accède à l'historique de mes commandes</li>
            <li>Je crée et j'actualise mes listes d'envies</li>
            <li>Je gère mes adresses de livraisons</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Login;
