import { FacebookLogo,  InstagramLogo , PaperPlaneRight  } from 'phosphor-react';
import "./footer.css";
import React, { useState,useEffect } from 'react';
import PackageIcon from '../resources/Icons/Livreur_noir.png';
import LivraisonIcon from '../resources/Icons/Shipping_24_noir.png';
import RetourIcon from '../resources/Icons/Retour_noir.png';
import ServiceIcon from '../resources/Icons/Service_client_noir.png';
import axios from 'axios';


export const Footer = () => {
  const [email, setEmail] = useState("")
  const [refInfo, setRefInfo] = useState([])
  useEffect(() => {
    const fetchData = async () => {
        try{
      const response = await axios.post('https://aroobee-api.azurewebsites.net/Login/GetRefInfo',{
      headers: {"Access-Control-Allow-Origin": "*"}}
    ); // Replace with your API endpoint
    setRefInfo(response.data);
    }catch(err){
    }
    };
    fetchData();
    
  
  }, []);
    return (
        // <!-- Footer -->
        <footer>
              <div className="features">
                  <div className="card card1">
                    <img className='icon icon1' src={PackageIcon} alt="PackageIcon"/>
                    <p>Livraison gratuite à partir {refInfo.freeShippingMin}$ d'achats</p>
                  </div>
                  <div className="card card2">
                    <img className='icon icon2' src={LivraisonIcon} alt="LivraisonIcon"/>
                    <p>Livraison en <br />{refInfo.shippingTime} heures</p>
                  </div>
                  <div className="card card3">
                    <img className='icon icon3' src={RetourIcon} alt="RetourIcon"/>
                    <p>Retour gratuit</p>
                  </div>
                  <div className="card card4">
                    <img className='icon icon4' src={ServiceIcon} alt="ServiceIcon"/>
                    <p>Service client</p>
                  </div>
                </div>
            <div className='footer'>

          {/* <!-- Exclusive Section --> */}
          <div className='footer-section'>
            <h2>Newsletter</h2>
            <p>Inscrivez-vous</p>
            <div className="input-container">
                <input value={email}type="email" className="blue-input" placeholder="Enter your email"/>
                <span className="phosphor-icon" ><PaperPlaneRight size={25}/></span>
            </div>
            <div className='SocialLogos'>
              <div>
                <a href="https://www.facebook.com/aroobee.store"><FacebookLogo className="logo" size={32} /></a>
                <a href='https://www.instagram.com/aroobee.store/'><InstagramLogo className="logo"  size={32} /></a>
                {/*<TiktokLogo className="logo"  size={32} />*/}
                </div>
                </div>
            </div>

        
          {/* <!-- Support Section --> */}
          <div className='footer-section'>
            <h2>Support</h2>
            {/*<p>862 Rue René Gabriel Belleau</p>
            <p>Québec G1V-3Y3</p>*/}
            <p>{refInfo.contactEmail}</p>
            <p>{refInfo.contactPhone1}</p>
            {refInfo.contactPhone1!=refInfo.contactPhone2&&<p>{refInfo.contactPhone2}</p>}
            <p>{refInfo.adresse}</p>
          </div>
        
          {/* <!-- Account Section --> */}
            <div className='footer-section'>
            <h2>Compte</h2>
            <ul>
                <li><a href="/login">Mon compte</a></li>
                <li><a href="/login">Login</a> / <a href="/Inscription">Inscription</a></li>
                <li><a href="/panier">Panier</a></li>
                <li><a href="/Profil/Malisteenvie">Favoris</a></li>
            </ul>
            </div>
            
          {/* <!-- Quick Link Section --> */}
          <div className='footer-section'>
            <h2>Accès rapide</h2>
            <ul>
                <li><a href="#"> Politique de confidentialité</a></li>
                <li><a href="#">Conditions d'utilisation</a></li>
                <li><a href="#">FAQ</a></li>
                <li><a href="#">Contact</a></li>
            </ul>
          </div>
        
    
          </div>
        </footer>               
    )
}