import "./ProfilInfos.css";
import React, { useState,useEffect } from 'react';
import axios from 'axios';

const ProfilInfos = ({  }) => {
  const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;

  const [formValues, setFormValues] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    filled:false
  });  
  useEffect(() => {
    const fetchData = async () => {
        try{
      const response = await axios.get('https://aroobee-api.azurewebsites.net/User/GetAllInfoOfUser',{
        headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}}
        ); 
        setFormValues({ ...formValues,filled:true, fname: response.data.firstName,lname:response.data.lastName,phone:response.data.phone,email:response.data.email});
    }catch(err){
      if(err.response.status===401){
        localStorage.setItem('error', JSON.stringify(err.response.status));
        sessionStorage.clear();
      }
    }
    };
    if(!formValues.filled){    fetchData();
    }

    fetchData();

    }, []);
    async function sendAxiosPostRequest(url, data, config = {}) {
      try {
        const response = await axios.post(url, data, config);
        // Handle successful response
        return response.data; // Access response data
      } catch (error) {
        // Handle errors
        return null; // Or throw an error if needed
      }
    }
    const fullname_REGEX = /^[a-zA-Z\- ]{2,30}$/;
    const [formErrors, setFormErrors] = useState({});
    const handleInputChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        const newFormErrors = {};
    
        if (!fullname_REGEX.test(formValues.fname)) {
          newFormErrors.fname = 'Prénom invalide';
        }
    
        if (!fullname_REGEX.test(formValues.lname)) {
          newFormErrors.lname = 'Nom de famille invalide';
        }
    
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
          newFormErrors.email = 'E-mail invalide';
        }
    
        if (!/^\d{10}$/.test(formValues.phone)) {
          newFormErrors.phone = 'Téléphone est requis';
        }
        setFormErrors(newFormErrors);

    if (Object.keys(newFormErrors).length === 0) {
        const submitForm={
          "firstName":   formValues.fname,
          "lastName": formValues.lname,
          "phone": formValues.phone,
          "email": formValues.email,
          "token":""
          }
          delete submitForm.filled
      sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/User/UpdateUserInfo',submitForm,{headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
        .then(responseData => {
          
        })
    }
  };
    return (
    <>
    <div className="ProfilInfos">
        <b>Modifier mes informations</b>
        <form onSubmit={handleSubmit}>

        <div className="FullName">
            <input
                type="text"
                id="fname"
                name="fname"
                className="InputName"
                placeholder="Prénom*"
                value={formValues.fname}
                onChange={handleInputChange}
            />

            <input
                type="text"
                id="lname"
                name="lname"
                
                placeholder="Nom de famille*"
                value={formValues.lname}
                onChange={handleInputChange}
            />

            {formErrors.fname? <p>{formErrors.fname}</p>:<p></p>}
            {formErrors.lname? <p>{formErrors.lname}</p>:<p></p>}
        </div>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="E-mail*"
          value={formValues.email}
          onChange={handleInputChange} readOnly
        />
            {formErrors.email && <p>{formErrors.email}</p>}
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Téléphone*"
          value={formValues.phone}
          onChange={handleInputChange}
        />
        {formErrors.phone && <p>{formErrors.phone}</p>}

        <button type="submit">Enregistrer les modifications</button>
        </form>
    </div>
    </>
    );
};

export default ProfilInfos;