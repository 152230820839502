import React, { useState } from 'react';
import axios from "axios";

import "./Inscription.css";
import RetourIcon from '../../resources/Icons/Eye_noir.png';
function Inscription() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confimPasswordShown, setConfimPasswordShown] = useState(false);
  
 const [showSecondAddress, setShowSecondAddress] = useState(false);
  const [formValues, setFormValues] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    address: '',
    Complémentaddress: '',
    zip: '',
    city: '',
    province: '',
    delivery: false,
    address2: '',
    Complémentaddress2: '',
    zip2: '',
    city2: '',
    password: '',
    confirm: '',
    newsletter: false,
  });
  async function sendAxiosPostRequest(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      // Handle successful response
      return response.data; // Access response data
    } catch (error) {
      // Handle errors
      return null; // Or throw an error if needed
    }
  }

  const [formErrors, setFormErrors] = useState({});

  const fullname_REGEX = /^[a-zA-Z\- ]{2,30}$/;
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\-^&*()_+])[A-Za-z0-9!@#$%^&\-*()_+]{8,20}$/;

  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = () => {
    setShowSecondAddress(!showSecondAddress);
    setFormValues({ ...formValues, delivery: !formValues.delivery });

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {};

    if (!fullname_REGEX.test(formValues.fname)) {
      newFormErrors.fname = 'Vérifier le champs Prénom';
    }

    if (!fullname_REGEX.test(formValues.lname)) {
      newFormErrors.lname = 'Vérifier le champs de Nom de famille';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      newFormErrors.email = 'Vérifier le champs E-mail';
    }

    if (!/^\d{10}$/.test(formValues.phone)) {
      newFormErrors.phone = 'Vérifier le champs Téléphone';
    }
    if(/^\d+$/.test(formValues.address.replace(/\s|-/g, ""))){
      newFormErrors.address = "Vérifier le champs Adresse";
    }else{
      if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,59}$/.test(formValues.address)) {
        newFormErrors.address = 'Vérifier le champs Adresse';
      }
    }
    
    formValues.zip=formValues.zip.replace(/\s|-/g, "");
    if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6}$/.test(formValues.zip)) {
      newFormErrors.zip = 'Vérifier le champs Code postal';
    }
    if(formValues.Complémentaddress){
      if(/^\d+$/.test(formValues.Complémentaddress.replace(/\s|-/g, ""))){
        newFormErrors.Complémentaddress = "Vérifier le champs de complément d'adresse";
      }else{
        if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,49}$/.test(formValues.Complémentaddress)) {
          newFormErrors.Complémentaddress = "Vérifier le champs de complément d'adress";
        }
      }
      
    }else{
      formValues.Complémentaddress=null;
    }
    
    
    if (!/^[a-zA-Z0-9\\s,'-.\#éçèùàôêâû]{5,49}$/.test(formValues.city)) {
      newFormErrors.city = 'Vérifier le champs Ville';
    }


    if (showSecondAddress) {
      if(/^\d+$/.test(formValues.address2.replace(/\s|-/g, ""))){
        newFormErrors.address2 = "Adresse invalide";
      }else{
        if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,59}$/.test(formValues.address2)) {
          newFormErrors.address2 = 'Adresse invalide';
        }
      }
      if(formValues.Complémentaddress2){
        if(/^\d+$/.test(formValues.Complémentaddress2.replace(/\s|-/g, ""))){
          newFormErrors.Complémentaddress2 = "Adresse non valide";
        }else{
          if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,49}$/.test(formValues.Complémentaddress2)) {
            newFormErrors.Complémentaddress2 = 'Adresse invalide';
          }
        }
        
      }else{
        formValues.Complémentaddress2=null;
      }
      
    
    if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6}$/.test(formValues.zip2)) {
      newFormErrors.zip2 = 'Code postal de livraison est invalide';
    }

    if (!/^[a-zA-Z0-9\\s,'-. \#éçèùàôêâû]{5,49}$/.test(formValues.city2)) {
      newFormErrors.city2 = 'Ville de livraison est invalide';
    }
    }else{
      formValues.Complémentaddress2=null;
      formValues.address2="";
      formValues.zip2="";
      formValues.city2="";
    }

    formValues.province="Québec";
    if (!PWD_REGEX.test(formValues.password)) {
      newFormErrors.password = 'Mot de passe doit être 8 charactères contenant au moins une lettre majuscule,une lettre minuscule, unn chiffre et un symbole';
    }

    if (formValues.password !== formValues.confirm) {
      newFormErrors.confirm = 'Codes de confirmation ne correspondent pas';
    }

    setFormErrors(newFormErrors);

    if (Object.keys(newFormErrors).length === 0) {
      let submitform ={}
      if (!showSecondAddress){
        submitform={
          "password" : formValues.password,
          "email": formValues.email,
          "firstName": formValues.fname,
          "lastName": formValues.lname,
          "phone": formValues.phone,
          "addresses":[{
            "addressId":0,
            "addressName":formValues.address,
            "additionalAddress":formValues.Complémentaddress,
            "zipCode":formValues.zip,
            "city":formValues.city,
            "firstName": formValues.fname,
            "lastName": formValues.lname,
            "province":formValues.province, 
            "clientId": 0,
            "livraison":true,
            "factuartion":true
          }]}
      }else{
        submitform ={
          "password" : formValues.password,
          "email": formValues.email,
          "clientId":0,
          "firstName": formValues.fname,
          "lastName": formValues.lname,
          "phone": formValues.phone,
          "addresses":[{
            "addressId":0,
            "addressName":formValues.address,
            "firstName": formValues.fname,
            "lastName": formValues.lname,
            "additionalAddress":formValues.Complémentaddress,
            "zipCode":formValues.zip.toUpperCase(),
            "city":formValues.city,
            "province":formValues.province, 
            "clientId": 0,
            "livraison":true,
            "factuartion":true
          },{
            "addressId":0,
            "addressName":formValues.address2,
            "firstName": formValues.fname,
            "lastName": formValues.lname,
            "additionalAddress":formValues.Complémentaddress2,
            "zipCode":formValues.zip2.toUpperCase(),
            "city":formValues.city2,
            "province":formValues.province, 
            "clientId": 0,
            "livraison":true,
            "factuartion":false
          }]
      }
      }
      

      sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Login/ClientRegister',submitform,{headers: {"Access-Control-Allow-Origin": "*"}})
        .then(responseData => {

          
        })
    }
  };

  return (
    <form className="Inscription-container" onSubmit={handleSubmit}>
      <h1>Créez votre compte client</h1>

      <div>
        <div className="doublebox">
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="Prénom*"
            value={formValues.fname}
            onChange={handleInputChange}
          />
          
          <input
            type="text"
            id="lname"
            name="lname"
            placeholder="Nom de famille*"
            value={formValues.lname}
            onChange={handleInputChange}
          />
          
        </div>
        <div className="doublebox">
        {formErrors.fname ? <p>{formErrors.fname}</p>:<p></p>}
        {formErrors.lname ? <p>{formErrors.lname}</p>:<p></p>}
        </div>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="E-mail*"
          value={formValues.email}
          onChange={handleInputChange}
        />
        {formErrors.email && <p>{formErrors.email}</p>}

        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Téléphone*"
          value={formValues.phone}
          onChange={handleInputChange}
        />
        {formErrors.phone && <p>{formErrors.phone}</p>}

        <input
          type="text"
          id="address"
          name="address"
          placeholder="Adresse*"
          value={formValues.address}
          onChange={handleInputChange}
        />
        {formErrors.address && <p>{formErrors.address}</p>}

        <input
          type="text"
          id="Complémentaddress"
          name="Complémentaddress"
          placeholder="Complément d'adresse"
          value={formValues.Complémentaddress}
          onChange={handleInputChange}
        />
        {formErrors.Complémentaddress && <p>{formErrors.Complémentaddress}</p>}
        <div className="doublebox">
          <input
            type="text"
            id="zip"
            name="zip"
            placeholder="Code postal*"
            value={formValues.zip}
            onChange={handleInputChange}
          />
          

          <input
            type="text"
            id="city"
            name="city"
            placeholder="Ville*"
            value={formValues.city}
            onChange={handleInputChange}
          />
          
          
        </div>
        <div className="doublebox">
            {formErrors.zip && <p>{formErrors.zip}</p>}
            {formErrors.city && <p>{formErrors.city}</p>}
          </div>
        <input
          type="text"
          id="province"
          name="province"
          placeholder="Province du Québec*"
          disabled
        />
        {formErrors.province && <p>{formErrors.province}</p>}

        <div>
          <input
            type="checkbox"
            id="delivery"
            name="delivery"
            checked={formValues.delivery}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="delivery">Adresse différente pour la livraison</label>
        </div>
        {showSecondAddress && (
          <div className="secondaddress">
            <input
              type="text"
              id="address2"
              name="address2"
              placeholder="Adresse*"
              value={formValues.address2}
              onChange={handleInputChange}
            />
            {formErrors.address2 ? <p>{formErrors.address2}</p>:<p></p>}

            <input
              type="text"
              id="Complémentaddress2"
              name="Complémentaddress2"
              placeholder="Complément d'adresse*"
              value={formValues.Complémentaddress2}
              onChange={handleInputChange}
            />
            {formErrors.Complémentaddress2 && <p>{formErrors.Complémentaddress2}</p>}
            <div className="doublebox">
              <input
                type="text"
                id="zip2"
                name="zip2"
                placeholder="Code postal*"
                value={formValues.zip2}
                onChange={handleInputChange}
              />

              <input
                type="text"
                id="city2"
                name="city2"
                placeholder="Ville*"
                value={formValues.city2}
                onChange={handleInputChange}
              />
            </div>
            <div className="doublebox">
            {formErrors.zip2? <p>{formErrors.zip2}</p>:<p></p>}
            {formErrors.city2 ? <p>{formErrors.city2}</p>:<p></p>}
          </div>
          </div>
        )}
        <div className='input-container'>
        <input className='eyeinput'
          type={passwordShown ? "text" : "password"}
          id="password"
          name="password"
          placeholder="Mot de passe*"
          value={formValues.password}
          onChange={handleInputChange}
        />
        <img className='phosphoreIcon' src={RetourIcon} alt="PackageIcon"onClick={(e) => {
          setPasswordShown(!passwordShown);
          e.preventDefault();
        }} />
        </div>
        {formErrors.password && <p>{formErrors.password}</p>}
        <div className='input-container'>

        <input  className='eyeinput'
          type={confimPasswordShown ? "text" : "password"}
          id="confirm"
          name="confirm"
          placeholder="Code de confirmation*"
          value={formValues.confirm}
          onChange={handleInputChange}
        />
        <img className='phosphoreIcon' src={RetourIcon} alt="PackageIcon"onClick={(e) => {
          setConfimPasswordShown(!confimPasswordShown);
          e.preventDefault();
        }} />
        </div>
        {formErrors.confirm && <p>{formErrors.confirm}</p>}

        <div>
          <input
            type="checkbox"
            id="newsletter"
            name="newsletter"
            checked={formValues.newsletter}
            onChange={(e) =>
              setFormValues({ ...formValues, newsletter: e.target.checked })
            }
          />
          <label htmlFor="newsletter">Oui, je souhaite m'inscrire à la newsletter.</label>
        </div>
        <div className='ptext'> 
        <p className='abovebutton'>
          En vous enregistrant, vous prenez acte de la présente politique de protection
          des données personnelles.
        </p>
        </div>

        <button type="submit">Confirmer</button>
      </div>
    </form>
  );
}

export default Inscription;