import "./ProfilCommande.css";
import { Package } from "phosphor-react";
import axios from "axios";
import React, { useState,useEffect } from 'react';



const ProfilCommande = ({  }) => {
    const [commandes, setCommandes] = useState([]);
    useEffect(() => {
        const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
        if(Token){
        const fetchData = async () => {
            try{
                const response = await axios.get("https://aroobee-api.azurewebsites.net/Order/GetOrdersOfClient",{
                headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}}
                ); // Replace with your API endpoint
                setCommandes(response.data);
            }catch(err){
                if(err.response.status===401){
                    localStorage.setItem('error', JSON.stringify(err.response.status));
                    sessionStorage.clear();
                  }}
            
        };
        fetchData();
    }
    }, []);
    // const commandes=[{
    //     commandenum:22584318512,
    //     dateCommande: "15 Février 2024",
    //     prixCommande:22.46,
    //     CommandeStatus: "Livrée"
    // },{
    //     commandenum:22584318512,
    //     dateCommande: "15 Février 2024",
    //     prixCommande:23.46,
    //     CommandeStatus: "En cours de livraison"
    // },{
    //     commandenum:22584318512,
    //     dateCommande: "15 Février 2024",
    //     prixCommande:23.46,
    //     CommandeStatus: "En cours de préparation"
    // },{
    //     commandenum:22584318512,
    //     dateCommande: "15 Février 2024",
    //     prixCommande:23.46,
    //     CommandeStatus: "Retour demandé"
    // },{
    //     commandenum:22584318512,
    //     dateCommande: "15 Février 2024",
    //     prixCommande:23.46,
    //     CommandeStatus: "Retour demandé"
    // },{
    //     commandenum:22584318512,
    //     dateCommande: "15 Février 2024",
    //     prixCommande:23.46,
    //     CommandeStatus: "Commande retournée"
    // }]
    return (
        <>
        <b className="Title">Mes Commandes</b>
        <div>
        {commandes.map((commande) => (
            <div className="Commande">
                <div className="commande_box">
                <div className="Spaces"><div><Package size={21} /></div><div className={"Commande_Status ".concat(commande.orderState.replace(/\s/g, "_"))}>{commande.orderState}</div></div>
                <div>Commande n°{commande.orderRef} du {commande.orderDate}  </div>
                </div>
                <div className="Spaces Price_box"><div><b>{commande.orderPrice}</b></div><div><button>Télécharger ma facture</button></div></div>
            </div>
            ))}
            </div>
        </>
    );
};

export default ProfilCommande;