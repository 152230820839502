import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Navbar} from "./Components/navbar";
import ErrorDisplay from './Components/ErrorDisplay';
import Error from "./Pages/Error/Error";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import Inscription from "./Pages/Inscription/Inscription";
import ExpositionCategorie from "./Pages/ExpositionCategorie/ExpositionCategorie";
import Product from './Pages/Product/Product';
import Panier from './Pages/Panier/Panier';
import Profil from './Pages/Profil/Profil';
import {Footer} from "./Components/footer";
import RecapCommande from './Pages/Commande/RecapCommance';
import PrivateRoute from"./Components/SecurityComponents/PrivateRoute";
import { AuthProvider } from './Components/SecurityComponents/AuthContext'; // Or your custom hook provider
import { Navigate } from 'react-router-dom';
import Disconnect from './Components/SecurityComponents/Disconnect';
import UserInfo from './Pages/Commande/UserInfo';
import ValidationCommande from './Pages/Commande/ValidationCommande';
import PaiementInfo from './Pages/Commande/PaiementInfo';
import{ useState } from "react";

function App() 
{
  const isLoggedIn = JSON.parse(sessionStorage.getItem('UserTokken'));
  const [maniPage, setmaniPage] = useState("/");

  return (
    <div className="App">
    {/* adddin routes */}
    <AuthProvider>
    <BrowserRouter>
      {/* present in every route */}
      <Navbar/>
      <ErrorDisplay/>
      <div>
      <Routes>
            <Route path="/login" element={!isLoggedIn ? <Login maniPage={maniPage}/> : <Navigate to="/" replace />} />
            <Route path="/Login" element={!isLoggedIn ? <Login maniPage={maniPage}/> : <Navigate to="/" replace />} />
            <Route path="/inscription" element={!isLoggedIn ? <Inscription /> : <Navigate to="/" replace />} />
            <Route path="/Inscription" element={!isLoggedIn ? <Inscription /> : <Navigate to="/" replace />}/>

                <Route path="/Profil/*" element={<PrivateRoute>
                                <Profil />
                            </PrivateRoute>} />
                <Route path="/profil/*" element={<PrivateRoute>
                                <Profil />
                            </PrivateRoute>} />
                <Route path="/Commande/UserInfo" element={
                  <PrivateRoute>
                        <UserInfo />
                      </PrivateRoute>} />
                <Route path="/Commande/paiement" element={
                  <PrivateRoute>
                        <PaiementInfo />
                      </PrivateRoute>} />
                <Route path="/Commande/ValidationCommande" element={
                  <PrivateRoute>
                        <ValidationCommande />
                      </PrivateRoute>} />
                <Route path="/Commande/RecapCommance" element={
                  <PrivateRoute>
                        <RecapCommande />
                      </PrivateRoute>} />

        <Route path="/" element={<Home/>}/>

        <Route path="/Produit/*" element={<Product />} />
        <Route path="/produit/*" element={<Product />} />
        
        <Route path="/Panier" element={<Panier setmaniPage={setmaniPage}/>}/>
        <Route path="/Exposition/*" element={<ExpositionCategorie />}/>
        <Route path="/exposition/*" element={<ExpositionCategorie />}/>

        <Route path="*" element={<Error/>}/>
        <Route path="/déconnexion" element={<Disconnect/>}/>
      </Routes>
      </div>
    </BrowserRouter>
    <Footer/>
    </AuthProvider>
  </div>
  );
}

export default App;
