import "./ProfilPass.css";
import React, { useState } from 'react';
import RetourIcon from '../../resources/Icons/Eye_noir.png';
import { Lock } from "phosphor-react";
import axios from "axios";

const ProfilPass = ({  }) => {
    const [confimPasswordShown, setConfimPasswordShown] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [newpasswordShown, seNewpasswordShown] = useState(false);
    const [formValues, setFormValues] = useState({
        password: '',
        newpassword: '',
        confirm: '',
      });
    
      const [formErrors, setFormErrors] = useState({});

    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\-^&*()_+])[A-Za-z0-9!@#$%^&\-*()_+]{8,20}$/;
    const handleInputChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      };
      async function sendAxiosPostRequest(url, data, config = {}) {
        try {
          const response = await axios.post(url, data, config);
          // Handle successful response
          return response.data; // Access response data
        } catch (err) {
          if(err.response.status===401){
            localStorage.setItem('error', JSON.stringify(err.response.status));
            sessionStorage.clear();
          }
        }
      }
    const handleSubmit = (e) => {
        e.preventDefault();
        const newFormErrors = {};


        if (!PWD_REGEX.test(formValues.password)) {
            newFormErrors.password = 'Mot de passe doit être 8 charactères contenant au moins une lettre majuscule,une lettre minuscule, unn chiffre et un symbole';
        }
          if(!PWD_REGEX.test(formValues.newpassword)){
            newFormErrors.newpassword = 'Mot de passe doit être 8 charactères contenant au moins une lettre majuscule,une lettre minuscule, unn chiffre et un symbole';
            if (formValues.newpassword === formValues.password) {
                newFormErrors.newpassword = "Le nouveau mot de passe ne peut pas être l’ancien mot de passe";
            }}
      
          if (formValues.newpassword !== formValues.confirm) {
            newFormErrors.confirm = 'Codes de confirmation ne correspondent pas';
          }
          setFormErrors(newFormErrors);
          if (Object.keys(newFormErrors).length === 0) {
            const email="faycelsassi@gmail.com"
            formValues.email=email;
            const DateToSubmit={
              "email": email,
              "oldPassword": formValues.password,
              "newPassword": formValues.newpassword,
              "confirmPassword": formValues.confirm
            }
            sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Login/UpdatePassword',DateToSubmit,{headers: {"Access-Control-Allow-Origin": "*"}})
            .then(responseData => {
                // window.location.reload(true);
                alert("Password Changed")
            })
            .catch(error => {
                // Handle errors
            });
          }
        };

      

    return (
        <>
            <div><Lock size={32} />


            <b>Modifier le mot de passe</b>
            </div>
            <form className="Pass-container" onSubmit={handleSubmit}>

                <div className='input-container'>
                <input className='eyeinput'
                type={passwordShown ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Mot de passe actuel*"
                value={formValues.password}
                onChange={handleInputChange}
                />
                <img className='phosphoreIcon' src={RetourIcon} alt="PackageIcon"onClick={(e) => {
                setPasswordShown(!passwordShown);
                e.preventDefault();
                }} />
                </div>
                {formErrors.password && <p>{formErrors.password}</p>}
                <div className='input-container'>

                <input  className='eyeinput'
                type={newpasswordShown ? "text" : "password"}
                id="newpassword"
                name="newpassword"
                placeholder="Nouveau mot de passe*"
                value={formValues.newpassword}
                onChange={handleInputChange}
                />
                <img className='phosphoreIcon' src={RetourIcon} alt="PackageIcon"onClick={(e) => {
                seNewpasswordShown(!newpasswordShown);
                e.preventDefault();
                }} />
                </div>
                {formErrors.newpassword && <p>{formErrors.newpassword}</p>}

                <div className='input-container'>

                <input  className='eyeinput'
                type={confimPasswordShown ? "text" : "password"}
                id="confirm"
                name="confirm"
                placeholder="Confirmation de mot de passe*"
                value={formValues.confirm}
                onChange={handleInputChange}
                />
                <img className='phosphoreIcon' src={RetourIcon} alt="PackageIcon"onClick={(e) => {
                setConfimPasswordShown(!confimPasswordShown);
                e.preventDefault();
                }} />
                </div>
                
                {formErrors.confirm && <p>{formErrors.confirm}</p>}
                <button type="submit">Confirmer</button>

            </form>
        </>
    );
};

export default ProfilPass;