import "./ProfilFavoris.css";
import React, { useState,useEffect } from 'react';
import ProductFavComponent from "./ProductFavComponent";
import axios from "axios";

const ProfilFavoris = ({  }) => {
    const email="faycelsassi@gmail.com";
    const [products, setProducts] = useState([]);
    useEffect(() => {
        const url='https://aroobee-api.azurewebsites.net/Product/GetFavoriteProducts?ClientMail='.concat(email)

        const fetchData = async () => {
            try{
                const response = await axios.get(url,{
                headers: {"Access-Control-Allow-Origin": "*"}}
                ); // Replace with your API endpoint
                setProducts(response.data);
            }catch(err){
              if(err.response.status===401){
                localStorage.setItem('error', JSON.stringify(err.response.status));
                sessionStorage.clear();
              }}
        };
        fetchData();

        

    }, []);

    return (
      
        <div className="FavorisContainer">
        <b>Ma liste d'envie</b>
        <div className="FavProd1">
        <section className="Fave-card-container">
        {products &&
          products.map(
            ({
              productId,
              productImg,
              productName,
              productMarque,
              productPrevPrice,
              productNewPrice,
              productMaxQuantity,
              productPriceLabel
            }) => (
              <ProductFavComponent
                id={productId}
                img={productImg}
                title={productName}
                marque={productMarque}
                prevPrice={productPrevPrice}
                newPrice={productNewPrice}
                MaxQuantity={productMaxQuantity}
                productPriceLabel={productPriceLabel}


              />
            )
          )}
      </section>
        </div>
        </div>

      

        );
    };
    
    export default ProfilFavoris;