import "./ProfilContent.css";
import { useLocation } from 'react-router-dom';
import ProfilInfos from "./ProfilInfos";
import ProfilPass from "./ProfilPass";
import ProfilAdresses from "./ProfilAdresses";
import ProfilFavoris from "./ProfilFavoris";
import ProfilCommande from "./ProfilCommande";

const ProfilContent = ({  }) => {
    const location = useLocation();
    let variable=decodeURIComponent(location.pathname.replace('/profil/',''));
    variable=decodeURIComponent(location.pathname.replace('/Profil/',''));
    return (
    <>
    <div className="profil-content">
        {variable==="Mesinformations"?<ProfilInfos/>:null}
        {variable==="Mesadresses"?<ProfilAdresses/>:null}
        {variable==="ModifierMDP"?<ProfilPass/>:null}
        {variable==="Malisteenvie"?<ProfilFavoris/>:null}
        {variable==="Mescommandes"?<ProfilCommande/>:null}

    </div>
    </>
    );
};

export default ProfilContent;