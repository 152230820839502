import "./SidebarMarque.css";
import { useLocation } from 'react-router-dom';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
const SidebarOrigine = ({ handleChangePays }) => {
    const [Originedata, setOriginedata] = useState([]);
    const location = useLocation();
    let variable=decodeURIComponent(location.pathname.replace('/Exposition/',''));
    if (variable==="promo") variable="";
    useEffect(() => {
        const fetchData = async () => {
            try{
          const response = await axios.get('https://aroobee-api.azurewebsites.net/Product/GetContriesByCategory?Category='.concat(variable),{
          headers: {"Access-Control-Allow-Origin": "*"}}
        ); // Replace with your API endpoint
        setOriginedata(response.data);
        }catch(err){
          }
        };
    
        fetchData();
      }, []);
    return (
        <div className="SidebarChanger">
            <div>
            <h2 className="sidebar-title color-title">ORIGINE</h2>

            </div>
            {Originedata.map((Origine) => (
                <div>
                 <label className="sidebar-label-container">
                  <input type="checkbox" value={Origine.country} name="Origine test" onChange={handleChangePays}/>
                  <span className="checkmark all"> </span>
                  {Origine.country}
                 </label>
                </div>
            ))}

        </div>
        );
};

export default SidebarOrigine;