import React, { useState, useEffect } from 'react';
import { User,Heart,ShoppingCartSimple } from 'phosphor-react';
import "./navbar.css";
import Logo from '../resources/Logo.png';
import axios from 'axios';



export const Navbar = () => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);    
  const [categories, setCategories] = useState([]);
  const toggleMenu = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };
    let cartList = JSON.parse(localStorage.getItem('cartItems')) || [];
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        setIsOpen(!isOpen);
      };
      const userName= JSON.parse(sessionStorage.getItem('userName')) || null;

    useEffect(() => {
        const fetchData = async () => {
            try{
          const response = await axios.get('https://aroobee-api.azurewebsites.net/Product/GetAllCategories/',{
          headers: {"Access-Control-Allow-Origin": "*"}}
        ); // Replace with your API endpoint
        setCategories(response.data);
        }catch(err){
        }
        };
        let cartList = JSON.parse(localStorage.getItem('cartItems')) || [];
        fetchData();

      }, [cartList]);
      const dropdownItems = [
        { text: `Bonjour, ${userName}!`, isDivider: true }, // Personalized greeting
        { text: 'Mes Informations', link: '/Profil/Mesinformations' },
        { text: 'Mes Commandes', link: '/Profil/Mescommandes' },
        { text: 'Mes adresses', link: '/Profil/Mesadresses' },
        { text: 'Modifier le mot de passe', link: '/Profil/ModifierMDP' },
        { text: 'Déconnexion', link: '/Déconnexion' },
      ];
    

    return (
      <header>
        <nav >
            <div className="navbar">       
                <div className="navbar-left">
                    <a href='/'>
                <img src={Logo} alt="Logo"/></a>
            </div>
            <div className="navbar-center">
                <div className="navbar-list">
                    <ul className="list">
                        {
                            categories.map((category)=> (
                                <li className="list-item"><a href={"/Exposition/".concat(category.productCategoryName)}>{category.productCategoryName}</a></li>
                                ))}
                        {/* <!-- Add more list items as needed --> */}
                    </ul>
                </div>
                
            </div>
            <div className="navbar-right">
                {/* <div className="search-bar">
                    <input type="text" placeholder="Search"/>

                </div> */}
                <a className="icon-button" href='/Profil/Malisteenvie'><span className='fa-icons'><Heart size={25} /></span></a>
                    {userName?
                        <div className="dropdown-container"> {/* Added container for styling */}
                        <button className="icon-button" onClick={handleClick}>
                        <span className='fa-icons'> <User size="25" /></span>
                        </button>
                        {isOpen && (
                          <ul className="dropdown-list">
                            <li className="noncssdropdown" >Bonjour, {userName}!</li>
                            {dropdownItems.map((item, index) => (
                              <li key={index}>
                                {item.isDivider ? <hr className="noncssdropdown" key={index + 'divider'} /> : ( // Use key for dividers too
                                  <a href={item.link || '#'}> {/* Handle links and non-links */}
                                    {item.text}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>:<a className="icon-button" href='/login'><span className='fa-icons'><User size={25} onClick={handleClick}/></span> </a>}
                
                <a className="icon-button" href='/Panier'>
                {cartList.length===0?<ShoppingCartSimple size={25} />:<div className="numbered-cart-icon">
      <span className="cart-count">{cartList.length>0?cartList.length:null}</span>
      <ShoppingCartSimple size={25} />
    </div>}</a>
            </div>
            </div>

            
          
        </nav>
        <div className='HamburgerNavbar'>
          <div className='HamburgerNavbarHeader'>
            <a href='/'>
                    <img src={Logo} alt="Logo"/></a>
            <button class="hamburger" onClick={toggleMenu}>
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </button>
          </div>
          <div  className={isHamburgerOpen ? 'showHamburgerLinks' : 'hiddenHamburgerLinks'}>
            {categories.map((category)=> (
              <a href={"/Exposition/".concat(category.productCategoryName)}>{category.productCategoryName}</a>
            ))}
            {

            }<hr/>
             {userName?
             <div className='HamburgerUserIn'>
              <div className="HamburgerText" >Bonjour, {userName}!</div>
                            {dropdownItems.map((item, index) => (
                              <div className='HamburgerUserInLinks'>
                                {item.isDivider ? null : ( // Use key for dividers too
                                  <a href={item.link || '#'}> {/* Handle links and non-links */}
                                    {item.text}
                                  </a>
                                )}
                              </div>
                            ))}
              
             </div>
            :
            <a href='/login'><span className='fa-icons'><User size={16} onClick={handleClick}/>Login</span> </a>
             }
             <div>
           <a href='/Panier'><span className='fa-icons'>
            <div className="numbered-cart-icon">
      <span className="cart-count">{cartList.length>0?cartList.length:null}</span>
      <ShoppingCartSimple size={16} />
    </div>
            Panier</span></a>
            </div>

        </div>
        </div>
        
      </header>
            
    )
  }