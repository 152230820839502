import "./Panier.css";
import { Plus, Minus, Trash } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InteracImg from "../../resources/Icons/Interac_noir.png";
import LiquideImg from "../../resources/Icons/Hands_money_noir.png";
import VisaImg from "../../resources/Icons/Visa_big.png";
import MasterCardImg from "../../resources/Icons/Mastercard_200.png";
import axios from "axios";

function Panier({ setmaniPage }) {
  async function sendAxiosPostRequest(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      // Handle successful response
      return response.data; // Access response data
    } catch (error) {
      // Handle errors
      return null; // Or throw an error if needed
    }
  }
  const [cartItems, setCartItems] = useState([]);
  const [promo, setPromo] = useState("");
  const [pourboir, setPourboir] = useState("15%");
  function replaceObjectWithId(list, id, newObject) {
    if (!list || !newObject) {
      throw new Error("Invalid arguments: list and newObject are required.");
    }

    const index = list.findIndex((obj) => obj.id === id);

    if (index === -1) {
      list = [...list, newObject];
    } else {
      list[index] = newObject;
    }
    return list; // Return the updated list
  }
  let storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const handlePromoInputChange = (event) => {
    setPromo(event.target.value); // Update promo state with input value
  };

  useEffect(() => {
    // Retrieve cart items from localStorage
    setCartItems(storedCartItems);
  }, []);
  function handleAddQteChange(item) {
    const url =
      "https://aroobee-api.azurewebsites.net/Product/GetProductById?Id=".concat(
        item.id
      );
    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer ".concat(Token),
        },
      })
      .then((response) => {
        if (item.newQuantity + 1 <= response.data.productMaxQuantity) {
          let cartList = JSON.parse(localStorage.getItem("cartItems")) || [];
          item.newQuantity = item.newQuantity + 1;
          setCartItems(replaceObjectWithId(storedCartItems, item.id, item));
          cartList = replaceObjectWithId(storedCartItems, item.id, item);
          let updatedCartItems = renameColumns(
            replaceObjectWithId(storedCartItems, item.id, item),
            {
              id: "productId",
              newQuantity: "quantity",
              img: "photos",
              title: "productName",
              productPrice: "newPrice",
            }
          );
          const Token =
            JSON.parse(sessionStorage.getItem("UserTokken")) || null;
          const DataToSend = {
            cartId: 0,
            clientMail: "string",
            subTotal: 0,
            total: 0,
            cartItems: updatedCartItems,
          };
          if (Token) {
            sendAxiosPostRequest(
              "https://aroobee-api.azurewebsites.net/Cart/AddCart",
              DataToSend,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  Authorization: "Bearer ".concat(Token),
                },
              }
            ).then((response) => {});
          }
          localStorage.setItem("cartItems", JSON.stringify(cartList));
        }
      })
      .catch((error) => {
        // Handle errors
      });
  }
  function handleMinQteChange(item) {
    let cartList = JSON.parse(localStorage.getItem("cartItems")) || [];

    const newQuantity = item.newQuantity - 1;
    if (item.newQuantity > 0) {
      item.newQuantity = newQuantity;
      setCartItems(replaceObjectWithId(storedCartItems, item.id, item));
      cartList = replaceObjectWithId(storedCartItems, item.id, item);
      localStorage.setItem("cartItems", JSON.stringify(cartList));
    }
    if (newQuantity === 0) {
      setCartItems(cartItems.filter((obj) => obj.id !== item.id));
      cartList = cartItems.filter((obj) => obj.id !== item.id);
      localStorage.setItem("cartItems", JSON.stringify(cartList));
    }
  }
  function renameColumns(data, renameMap) {
    return data.map((item) => {
      const newItem = {};
      for (const key in item) {
        const newKey = renameMap[key] || key; // Use original key if not mapped
        newItem[newKey] = item[key];
      }
      return newItem;
    });
  }
  const Token = JSON.parse(sessionStorage.getItem("UserTokken")) || null;

  const navigate = useNavigate();

  function PasserCommande() {
    let updatedCartItems = renameColumns(cartItems, {
      id: "productId",
      newQuantity: "quantity",
      img: "photos",
      title: "productName",
      newPrice: "productPrice",
    });

    if (Token !== null) {
      setmaniPage("/Commande/UserInfo");
      let pb = 0;
      if (pourboir == "15%") {
        pb = 15;
      }
      if (pourboir == "0%") {
        pb = 0;
      }
      if (pourboir == "20%") {
        pb = 20;
      }
      if (pourboir == "30%") {
        pb = 30;
      }
      let prm = "";
      if (promo == "") {
        prm = "string";
      } else {
        prm = promo;
      }

      const DataToSubmit = {
        cart: {
          cartId: 0,
          clientMail: "string",
          subTotal: 0,
          total: 0,
          cartItems: updatedCartItems,
        },
        pourboir: pb,
        promo: prm,
      };
      sendAxiosPostRequest(
        "https://aroobee-api.azurewebsites.net/Order/AddOrder",
        DataToSubmit,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer ".concat(Token),
          },
        }
      )
        .then((response) => {})
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.setItem("error", JSON.stringify(err.response.status));
            sessionStorage.clear();
          }
        });
    } else {
      setmaniPage("/Panier");
    }
    navigate("/Commande/UserInfo");
  }

  // Calculate the total price using reduce and convert strings to numbers
  const totalPrice = cartItems.reduce((total, item) => {
    const itemPrice = parseFloat(item.newPrice) * parseFloat(item.newQuantity); // Convert string to float
    return total + itemPrice;
  }, 0);
  return (
    <>
      <div className="cart">
        <div className="basket">
          <div className="basket-labels">
            <b>Mon panier : </b> {cartItems.length} articles, montant total :{" "}
            <b>${totalPrice.toFixed(2)}</b>
          </div>
          <button className="Commander onlyMobileOrderButton" onClick={PasserCommande}>
            <p>Passer la commande</p>
          </button>
          {cartItems.map((item, index) => (
            <div className="basket-product" key={index}>
              <div className="item">
                <a className="productLink" href={"/produit/" + item.id}>
                  <div className="product-image">
                    <img
                      src={item.img}
                      alt="Placholder  2"
                      className="product-frame"
                    />
                  </div>
                  <div className="product-details">
                    <h1 className="productName">
                      <span className="item-quantity"></span>
                      {item.title}
                    </h1>
                    {item.MaxQuantity > 0 && <p className="Stock">En stock</p>}
                  </div>
                </a>
              </div>
              <div className="quantity">
                {item.newQuantity === 1 ? (
                  <button
                    className="HeartButton quantityButton"
                    value={item}
                    onClick={() => handleMinQteChange(item)}
                  >
                    <Trash size={32} />
                  </button>
                ) : (
                  <button
                    className="HeartButton quantityButton"
                    value={item}
                    onClick={() => handleMinQteChange(item)}
                  >
                    <Minus size={32} />
                  </button>
                )}
                <input
                  type="number"
                  name="quantity"
                  value={item.newQuantity}
                  min="1"
                  max="10"
                />
                <button
                  className="HeartButton quantityButton"
                  value={item}
                  onClick={() => handleAddQteChange(item)}
                >
                  <Plus size={32} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="summary_box">
          <div className="summary_container">
            <div className="summary">
              <b className="orderRecapLabel">Récapitulatif de la commande :</b>
              <div className="subTotalLabel">
                Sous-tolal
                <span className="summary_right subTotalLabel">
                  ${totalPrice.toFixed(2)}
                </span>
              </div>
              <div className="shippingLabel">
                Emballage et expédition
                <span className="summary_right shippingLabel">$7.00</span>
              </div>
              <div className="box_livraison">
                <div className="freeShippingText">
                  <b>Livraison gratuite,</b> il vous suffit d'ajouter encore{" "}
                  <b>
                    $
                    {totalPrice > 100
                      ? 0
                      : (100 - totalPrice.toFixed(2)).toFixed(2)}
                  </b>{" "}
                  d'articles.
                </div>
                <div className="Range_box">
                  <div className="zeroDollar">0$</div>
                  <div className="Range_bar">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={totalPrice.toFixed(2)}
                      class="hidden-range"
                      readOnly
                    />
                    <div
                      class="filled-bar"
                      style={{ width: `${(totalPrice / 100) * 100}%` }}
                    ></div>
                  </div>
                  <div className="Dollar100">100$</div>
                </div>
              </div>
              <div>
                <div className="green_txt">Livraison estimée dans 24h </div>
                <div className="Promo_box">
                  <input
                    type="text"
                    id="promo"
                    value={promo}
                    onChange={handlePromoInputChange}
                    placeholder="Code promo"
                  />
                  {/* <button>Appliquer</button> */}
                </div>
                <div className="Pourboir_box">
                  <div>
                    <b className="pourboireLabel">Pourboire :</b>
                  </div>
                  <div className="pourboir_box_container">
                    <input
                      type="button"
                      value="0%"
                      onClick={() => setPourboir("0%")}
                      className={
                        pourboir === "0%" ? "circle clickedin" : "circle"
                      }
                    />
                    <input
                      type="button"
                      value="15%"
                      onClick={() => setPourboir("15%")}
                      className={
                        pourboir === "15%" ? "circle clickedin" : "circle"
                      }
                    />
                    <input
                      type="button"
                      value="20%"
                      onClick={() => setPourboir("20%")}
                      className={
                        pourboir === "20%" ? "circle clickedin" : "circle"
                      }
                    />
                    <input
                      type="button"
                      value="30%"
                      onClick={() => setPourboir("30%")}
                      className={
                        pourboir === "30%" ? "circle clickedin" : "circle"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="box_livraison_2">
                <div>
                  <b className="totalAmountLabel">Montant total :</b>
                  <span className="summary_right">
                    <b className="totalAmount">
                      $
                      {totalPrice < 60
                        ? (
                            totalPrice +
                            7 +
                            (totalPrice * Number(pourboir.slice(0, -1))) / 100
                          ).toFixed(2)
                        : (
                            totalPrice +
                            (totalPrice * Number(pourboir.slice(0, -1))) / 100
                          ).toFixed(2)}
                    </b>
                  </span>
                </div>
                <div className="TPSLabel">TPS incluse : $0</div>
                <div className="TVQLabel">TVQ incluse : $0</div>
                <button className="Commander" onClick={PasserCommande}>
                  Passer la commande
                </button>
              </div>
            </div>
            <div className="SummaryImgContainer">
              <div className="PanierImg">
                {" "}
                <img src={MasterCardImg} alt="MasterCArd" />
              </div>
              <div className="PanierImg">
                {" "}
                <img src={InteracImg} alt="InteracImg" />
              </div>
              <div className="PanierImg">
                {" "}
                <img src={LiquideImg} alt="LiquideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Panier;
