import "./PaiementInfo.css";
import { Lock } from "phosphor-react"; 
import InteracImg from '../../resources/Icons/Interac_noir.png';
import LiquideImg from '../../resources/Icons/Hands_money_noir.png';
import VisaImg from '../../resources/Icons/Visa_200.png';
import MasterCardImg from '../../resources/Icons/Mastercard_200.png';
import{ useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";


function PaiementInfo({}) {
    async function sendAxiosPostRequest(url, data, config = {}) {
        try {
          const response = await axios.post(url, data, config);
          // Handle successful response
          return response.data; // Access response data
        } catch (err) {
            if(err.response.status===401){
                localStorage.setItem('error', JSON.stringify(err.response.status));
                sessionStorage.clear();
              }
          return null; // Or throw an error if needed
        }
      }
    const navigate=useNavigate();

    const [isChecked, setIsChecked] = useState('Interac');
    const handleChange = (event) => {
        setIsChecked(event.target.value);
      };
    const handleButtonChange = (event) => {
        let NewState=0;
        if(isChecked==='Interac'){
            NewState=1;
        }if(isChecked==='Argentliquide'){
            NewState=2;
        }
        const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
        sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Order/UpdateOrderPayement?PayementType='.concat(NewState),{},
      {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
      .then(response => {
      })
      navigate("/Commande/ValidationCommande");

        
      };
        return (
                <>
                    <div className="CommandePaiementPage">
                        <div className="mainText">
                        <h1><b><Lock size={22} /> Sélectionner un mode de paiement</b></h1>
                        </div>
                        {/* <div className="PaiementOptionContainer">

                        <div className="PaiementOption">
                            <div>
                                <input type="radio" id="CarteBancaire" name="Paiement" value="CarteBancaire" onChange={handleChange} />
                                {isChecked === 'CarteBancaire' ?<label for="CarteBancaire"><span className="checkedPayOption">Carte Bancaire</span></label>:<label for="CarteBancaire">Carte Bancaire</label>}
                            </div>
                            <div>  <img src={VisaImg} alt="Visa"/> <img src={MasterCardImg} alt="MasterCArd"/></div>
                            </div>
                            <div>{isChecked === 'CarteBancaire' && 
                                <form className="CarteContainer">
                                    <p>Paiement facile et en toute sécurité.     </p>
                                    <b>Numero de carte </b>
                                    <input name="numcarte" id="numcarte" type="number" placeholder="1234 5678 9012 3456" min={1000000000000000} max={9999999999999999}/>
                                    <b>Date d'expiration</b>
                                    <div className="doublebox">
                                    <input name="dateexp" id="dateexp" type="month" placeholder="MM/AA"/>
                                    <input name="CVV" id="CVV" type="number" placeholder="CVV"/>
                                    </div>
                                    <div>
                                        <b>Nome de la Carte</b>
                                        <input name="nomcarte"  type="text"  placeholder="F. Sassi"/>
                                    </div>
                                    <div> 
                                    <input type="Checkbox" name="SaveCard" id="SaveCard"/>
                                    <label for="SaveCard">Sauvegarder pour mes prochains paiements.</label>
                                    </div>
                                    <div>
                                        <b>Donner un nom à ma carte</b>
                                        <input name="newnomcarte" type="text" placeholder="Exemple : Carte 1"/>
                                    </div>
                                </form>}
                                </div>

                        </div> */}
                        <div className="PaiementOptionContainer">
                            <div className="PaiementOption">
                                <div>
                                <input type="radio" id="Interac" name="Paiement" value="Interac" onChange={handleChange}/>
                                <label for="Interac">{isChecked === 'Interac'? <span className="checkedPayOption">Interac</span>:<span>Interac</span>}</label>
                                </div>
                                <div>  <img src={InteracImg} alt="Interac"/></div>

                            </div>
                            
                            <div>{isChecked === 'Interac' && <p>Assurez- vous d'être présent au moment de la livraison.</p>}</div>

                        </div>
                        <div className="PaiementOptionContainer">

                        <div className="PaiementOption">
                            <div>
                                <input type="radio" id="Argent_liquide" name="Paiement" value="Argentliquide" onChange={handleChange}/>
                                <label for="Argent_liquide">{isChecked === 'Argentliquide'?<span className="checkedPayOption">Argent liquide</span>:<span>Argent liquide</span>}</label>
                            </div>
                            <div><img src={LiquideImg} alt="Argent Liquide"/></div>
                            
                        </div> 
                        <div>{isChecked === 'Argentliquide' && <p>Assurez- vous d'être présent au moment de la livraison.</p>}</div>

                        </div>
                        <button type="submit" onClick={handleButtonChange}>Confirmer</button>

                    </div>
                </>
            );
    };
    export default PaiementInfo;