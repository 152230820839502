import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  // Retrieve data from session storage
  const isLoggedIn = JSON.parse(sessionStorage.getItem('UserTokken'));

  return isLoggedIn ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
