import "./ProfilBoxes.css";
import { User,MapPinLine, Package,CurrencyCircleDollar,Heart,LockKey} from "phosphor-react";
import { useLocation } from "react-router-dom";

const ProfilBoxes = ({}) => {
   const location = useLocation();
  const variable = decodeURIComponent(location.pathname.replace("/Profil/", ""));
  return (
    <>
      <div className="ProfilBoxesContainer">
        <div className="ListProfilBoxes" >
            <a href="/Profil/Mesinformations">{variable=="Mesinformations"?<div className="ProfilBox ProfilBox_selected"><User  className="ProfilBoxIcon"  />Mes informations</div>:<div className="ProfilBox"><User  className="ProfilBoxIcon"  />Mes informations</div> }</a>
            <a href="/Profil/Mesadresses">{variable=="Mesadresses"?<div className="ProfilBox ProfilBox_selected"><MapPinLine  className="ProfilBoxIcon"  />Mes adresses</div>:<div className="ProfilBox"><MapPinLine  className="ProfilBoxIcon"/>Mes adresses</div>}</a>
            <a href="/Profil/Mescommandes">{variable=="Mescommandes"?<div className="ProfilBox ProfilBox_selected"><Package className="ProfilBoxIcon"  />Mes commandes</div>:<div className="ProfilBox"><Package className="ProfilBoxIcon"  />Mes commandes</div>}</a>
            </div>
            <div className="ListProfilBoxes" >
           {/* <a href="/Profil/Méthodesdepaiement"><div className="ProfilBox"><CurrencyCircleDollar  className="ProfilBoxIcon" size={32} />Modes de paiement</div></a> */}
            <a href="/Profil/Malisteenvie">{variable=="Malisteenvie"?<div className="ProfilBox ProfilBox_selected"><Heart  className="ProfilBoxIcon"  />Ma liste d'envies</div>:<div className="ProfilBox"><Heart  className="ProfilBoxIcon"  />Ma liste d'envies</div>}</a>
            <a href="/Profil/ModifierMDP">{variable=="ModifierMDP"?<div className="ProfilBox ProfilBox_selected"><LockKey className="ProfilBoxIcon"/>Modifier le mot de passe</div>:<div className="ProfilBox"><LockKey className="ProfilBoxIcon"  />Modifier le mot de passe</div>}</a>
   
        </div>
      </div>
    </>
  );
};

export default ProfilBoxes;