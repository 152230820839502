import React, { useState, useEffect } from 'react';
import ProductComponent from "../MultiplePageComponents/productComponent";
import { ArrowLeft,ArrowRight   } from 'phosphor-react';
import axios from "axios";

function ProductCarousel() {
  const [products, setProducts] = useState([])
  const [productsPerPage, setProductsPerPage] = useState(4);
  // Calculate products per page based on container height
  const calculateProductsPerPage = () => {
    const container = document.getElementById('product-wrapper'); // Replace with your container ID
    const containerHeight = container?.clientWidth || 0; // Handle potential null reference

    // Adjust calculation for your specific layout and desired spacing
    const estimatedProductHeight = 250; // Replace with average product height
    const availableHeightForProducts = containerHeight - 10/* Adjust for other elements in container */;

    if(containerHeight<250){
      return 1;
    }else{
      return Math.floor(availableHeightForProducts / estimatedProductHeight);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;

      try {
        const response = await axios.get(
          "https://aroobee-api.azurewebsites.net/Product/GetAllProductsByCategory?Category=Promos",
          {
            headers: { "Access-Control-Allow-Origin": "*" ,"Authorization":"Bearer ".concat(Token)},
          }
        ); // Replace with your API endpoint
        setProducts(response.data);
      } catch (err) {
      }
      
    };
  fetchData();
  setProductsPerPage(calculateProductsPerPage());

    const handleResize = () => {
      setProductsPerPage(calculateProductsPerPage());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);

  },[])
  const [startIndex, setStartIndex] = useState(0);
  

  const handlePrev = () => {
    setStartIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setStartIndex(prevIndex => Math.min(prevIndex + 1, products.length - productsPerPage));
  };

  const visibleProducts = products.slice(startIndex, startIndex + productsPerPage);

  return (
    <div className='product-carousel-container'>  
       <div className="product-carousel" >
          <button className="prev-arrow" onClick={handlePrev}><ArrowLeft size={32} /></button>   
          <div id="product-wrapper"className="product-wrapper">
            {visibleProducts.map((product) => (
                <div key={product.id} className="product">
                  <ProductComponent 
                  id={product.productId}
                  img={product.productImg}
                  title={product.productName}
                  prevPrice={product.productPrevPrice}
                  newPrice={product.productNewPrice}
                  marque={product.productMarque}
                  productPriceLabel={product.productPriceLabel}

                  />
                </div>
              ))}
            </div>
            <button className="next-arrow" onClick={handleNext}><ArrowRight size={32} /></button>      
      </div>
    <div className="ContentButton">
      <a href={"/Exposition/Promos"}><b>Voir toute la sélection</b></a>
    </div>
    </div>
 
    // ...
  );
}

export default ProductCarousel;