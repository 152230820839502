import "./ProfilAdresses.css";
import { useState,useEffect } from "react";
import axios from 'axios';
const ProfilAdresses = ({ }) => {
    const [adresses, setAdresses] = useState([]);
    const [formValues, setFormValues] = useState({
        id:0,
        address: '',
        Complémentaddress: '',
        zip: '',
        city: '',
        province: 'Québec',
        clientId: 0,
        firstName:'',
        lastName:'',
        livraison: false,
        facturation: false,
        phone:0
      });
      async function sendAxiosPostRequest(url, data, config = {}) {
        try {
          const response = await axios.post(url, data, config);
          // Handle successful response
          return response.data; // Access response data
        } catch (error) {
          // Handle errors
          return null; // Or throw an error if needed
        }
      }
    const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;

    const [formErrors, setFormErrors] = useState({});
    const handleInputChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      };
      const setValues = (e) => {
        const addressId = Number(e.target.value);
        let form={}
        adresses.map((address) => {
              if (address.addressId === addressId) {
                    form={
                        id:address.addressId,
                        address: address.addressName,
                        Complémentaddress: address.additionalAddress,
                        zip: address.zipCode,
                        city: address.city,
                        firstName: address.firstName,
                        lastName: address.lastName,
                        province: 'Québec',
                        clientId: address.clientId,
                        livraison: address.livraison,
                        facturation: address.facturation,
                        phone:address.phone
                        
                    }
                }
                    setFormValues(form);
                });}
      const handleSubmit = (e) => {
        e.preventDefault();
        const newFormErrors = {};

        if(/^\d+$/.test(formValues.address.replace(/\s|-/g, ""))){
            newFormErrors.address = "Adresse non valide";
          }else{
            if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,59}$/.test(formValues.address)) {
              newFormErrors.address = 'Adresse invalide';
            }
          }
          formValues.zip=formValues.zip.replace(/\s|-/g, "");
          if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6}$/.test(formValues.zip)) {
            newFormErrors.zip = 'Code postal est requis';
          }
          if(formValues.Complémentaddress){
            if(/^\d+$/.test(formValues.Complémentaddress.replace(/\s|-/g, ""))){
              newFormErrors.Complémentaddress = "Adresse non valide";
            }else{
              if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,49}$/.test(formValues.Complémentaddress)) {
                newFormErrors.Complémentaddress = 'Adresse invalide';
              }
            }
            
          }else{
            formValues.Complémentaddress=null;
          }
          
          
          if (!/^[a-zA-Z0-9\\s,'-. \#éçèùàôêâû]{5,49}$/.test(formValues.city)) {
            newFormErrors.city = 'Ville est requise';
          }
          setFormErrors(newFormErrors);

    if (Object.keys(newFormErrors).length === 0) {
        const DataToSubmit={
            "addressId":formValues.id,
            "addressName":formValues.address,
            "additionalAddress":formValues.Complémentaddress,
            "zipCode":formValues.zip.toUpperCase(),
            "city":formValues.city,
            "firstName":formValues.firstName,
            "lastName":formValues.lastName,
            "province":formValues.province,
            "clientId": formValues.clientId,
            "livraison": formValues.livraison,
            "facturation": formValues.facturation,
            "phone":formValues.phone
        }
        sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Address/UpdateAddress',DataToSubmit,{headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
                .then(responseData => {

                    window.location.reload(true);
                })
                .catch(error => {
                    // Handle errors
                });
        

    }  
    
  };
  const handleAddSubmit = (e) => {
    e.preventDefault();
    const newFormErrors = {};

    if(/^\d+$/.test(formValues.address.replace(/\s|-/g, ""))){
        newFormErrors.address = "Adresse non valide";
      }else{
        if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,59}$/.test(formValues.address)) {
          newFormErrors.address = 'Adresse invalide';
        }
      }
      formValues.zip=formValues.zip.replace(/\s|-/g, "");
      if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6}$/.test(formValues.zip)) {
        newFormErrors.zip = 'Code postal est requis';
      }
      if (!/^\d{10}$/.test(formValues.phone)) {
        newFormErrors.phone = 'Vérifier le champs Téléphone';
      }
      if(formValues.Complémentaddress){
        if(/^\d+$/.test(formValues.Complémentaddress.replace(/\s|-/g, ""))){
          newFormErrors.Complémentaddress = "Adresse non valide";
        }else{
          if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,49}$/.test(formValues.Complémentaddress)) {
            newFormErrors.Complémentaddress = 'Adresse invalide';
          }
        }
        
      }else{
        formValues.Complémentaddress=null;
      }
      
      
      if (!/^[a-zA-Z0-9\\s,'-. \#éçèùàôêâû]{5,49}$/.test(formValues.city)) {
        newFormErrors.city = 'Ville est requise';
      }
      setFormErrors(newFormErrors);

if (Object.keys(newFormErrors).length === 0) {
    const DataToSubmit={
        "addressId":formValues.id,
        "addressName":formValues.address,
        "additionalAddress":formValues.Complémentaddress,
        "zipCode":formValues.zip.toUpperCase(),
        "city":formValues.city,
        "firstName":formValues.firstName,
        "lastName":formValues.lastName,
        "province":formValues.province,
        "clientId": formValues.clientId,
        "livraison": formValues.livraison,
        "facturation": formValues.facturation,
        "phone":formValues.phone
    }
    sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Address/AddAddress',DataToSubmit,{headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
            .then(responseData => {

                window.location.reload(true);
            })
            .catch(error => {
                // Handle errors
            });
    

}  

};
    const email="faycelsassi@gmail.com";
    useEffect(() => {
        const fetchData = async () => {
            try{
          const response = await axios.get('https://aroobee-api.azurewebsites.net/Address/GetAddressOfClient',{
          headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}}
        ); // Replace with your API endpoint
        setAdresses(response.data);
        }catch(err){
          }
        };
    
        fetchData();

        }, []);

        
        const handleLivraisonChange = (event) => {
            sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Address/SwitchFlag?', {"AddressId":Number(event.target.value),"TypeToSwitch":1,  "emailClient": "string"        },{headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
                .then(responseData => {
                    // Process successful response
                })
                .catch(error => {
                    // Handle errors
                });
            const addressId = Number(event.target.value);
            const updatedAddresses = adresses.map((address) => {
              if (address.addressId === addressId) {
                return { ...address, livraison: !address.livraison }; // Update Livraison
              }
              return address; // Don't modify other addresses
            });
            setAdresses(updatedAddresses);
          };
          const handleFacturationChange = (event) => {
            sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Address/SwitchFlag?', {"AddressId":Number(event.target.value),"TypeToSwitch":2,"emailClient": "string"},{headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
                .then(responseData => {
                    // Process successful response
                })
                .catch(error => {
                    // Handle errors
                });

            const addressId = Number(event.target.value);
            const updatedAddresses = adresses.map((address) => {
              if (address.addressId === addressId) {
                return { ...address, facturation: !address.facturation }; // Update Livraison
              }
              return address; // Don't modify other addresses
            });
            setAdresses(updatedAddresses);
          };
          const handleDeleteChange = (event) => {
            sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Address/DeleteAddress', {"AddressId":Number(event.target.value),"TypeToSwitch":0,"emailClient": "string"},{headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
                .then(responseData => {
                    // Process successful response
                })
                .catch(error => {
                    // Handle errors
                });
                const idToDelete=Number(event.target.value); 
                const updatedAddresses =adresses.filter(item => item.addressId !== idToDelete);
                setAdresses(updatedAddresses);
          };
          const emptyValue=(event) =>{
            setFormValues({
              id:0,
              address: '',
              Complémentaddress: '',
              zip: '',
              city: '',
              province: 'Québec',
              clientId: 0,
              firstName:'',
              lastName:'',
              livraison: true,
              facturation: true,
              phone:0
            })
          }
    return (
    <>
        <b className="Title">Gérer mes adresses</b>
        <div className="ButtonAdd"><a ty href="#popup2" >Ajouter une addresse</a></div>
  
        {adresses.map((address)=>(
            <div className="Adressbox">
                <div className="AddressName"><b>{address.firstName} {address.lastName}</b></div>
                <div className="ComplementAddress"><b>{address.addressName}</b></div>
                <div className="ComplementAddressDesc">{address.additionalAddress}{address.additionalAddress && ","} {address.zipCode} {address.city}</div>
                <div className="TypeAddress">
                    {address.facturation?<div className="TypeFacturation">Adresse de Facturation</div>:null}
                    &nbsp;
                    {address.livraison?<div className="TypeLivraison">Adresse de Livraison</div>:null}
                </div>
                <div className="ButtonType">          
                {address.facturation ?<></>:<button  onClick={handleFacturationChange} value={address.addressId}> Définir adresse facturation   </button>} 
                {address.livraison ?<></>: <button className="ButtonRight" onClick={handleLivraisonChange} value={address.addressId}>Définir adresse Livraison   </button>  } 
                </div>
                <div className="UD">
                  <form action="#popup1">
                      <button type="submit"value={address.addressId}onClick={setValues}>Modifier</button>   
                  </form>
                  {adresses.length>1?<button  className="ButtonRight"  onClick={handleDeleteChange} value={address.addressId}>Supprimer</button> :null}

                </div>
                <div id="popup1" className="overlay">
                    <div className="popup">
                        <h2></h2>
                        <a className="close" onClick={emptyValue}href="#">&times;</a>
                        <form onSubmit={handleSubmit}>
                        <div className="content">
                        <div className="doublebox">
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Prénom*"
                                    value={formValues.firstName}
                                    onChange={handleInputChange}
                                />
                                

                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Nom*"
                                    value={formValues.lastName}
                                    onChange={handleInputChange}
                                />
                                
                                </div>
                                <div className="doublebox">
                                    {formErrors.firstName?<p>{formErrors.firstName}</p>:<div></div>}
                                    {formErrors.lastName ? <p>{formErrors.lastName}</p>:<div></div>}
                                </div>                        
                            <input
                                type="text"
                                id="address"
                                name="address"
                                placeholder="Adresse*"
                                value={formValues.address}
                                onChange={handleInputChange}
                                />
                                {formErrors.address && <p>{formErrors.address}</p>}

                                <input
                                type="text"
                                id="Complémentaddress"
                                name="Complémentaddress"
                                placeholder="Complément d'adresse"
                                value={formValues.Complémentaddress}
                                onChange={handleInputChange}
                                />
                                {formErrors.Complémentaddress && <p>{formErrors.Complémentaddress}</p>}
                                <div className="doublebox">
                                <input
                                    type="text"
                                    id="zip"
                                    name="zip"
                                    placeholder="Code postal*"
                                    value={formValues.zip}
                                    onChange={handleInputChange}
                                />
                                

                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="Ville*"
                                    value={formValues.city}
                                    onChange={handleInputChange}
                                />
                                <input 
                                type="text"
                                id="city"
                                name="city"
                                placeholder="Ville*"
                                value={formValues.city}
                                onChange={handleInputChange}
                                hidden
                            />
                                
                                </div>
                                <div className="doublebox">
                                    {formErrors.zip?<p>{formErrors.zip}</p>:<div></div>}
                                    {formErrors.city ? <p>{formErrors.city}</p>:<div></div>}
                                </div>
                                <input
                                type="text"
                                id="province"
                                name="province"
                                placeholder="Province du Québec*"
                                disabled
                                />
                                <input
                                type="number"
                                id="phone"
                                name="phone"
                                placeholder="phone*"
                                value={formValues.phone}
                                onChange={handleInputChange}
                                />
                                {formErrors.phone && <p>{formErrors.phone}</p>}
                                <div className="Buttons">
                                    <button type="submit" className="Save">Sauvegarder</button>
                                        <a href="#"className="Cancel" onClick={emptyValue}>Annuler</a>
                                </div>
                                
                            </div>
                            </form>
                    </div>
                </div>
                <div id="popup2" className="overlay">
                    <div className="popup">
                        <h2></h2>
                        <a className="close" onClick={emptyValue} href="#">&times;</a>
                        <form onSubmit={handleAddSubmit}>
                        <div className="content">
                        <div className="doublebox">
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Prénom*"
                                    value={formValues.firstName}
                                    onChange={handleInputChange}
                                />
                                

                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Nom*"
                                    value={formValues.lastName}
                                    onChange={handleInputChange}
                                />
                                
                                </div>
                                <div className="doublebox">
                                    {formErrors.firstName?<p>{formErrors.firstName}</p>:<div></div>}
                                    {formErrors.lastName ? <p>{formErrors.lastName}</p>:<div></div>}
                                </div>                        
                            <input
                                type="text"
                                id="address"
                                name="address"
                                placeholder="Adresse*"
                                value={formValues.address}
                                onChange={handleInputChange}
                                />
                                {formErrors.address && <p>{formErrors.address}</p>}

                                <input
                                type="text"
                                id="Complémentaddress"
                                name="Complémentaddress"
                                placeholder="Complément d'adresse"
                                value={formValues.Complémentaddress}
                                onChange={handleInputChange}
                                />
                                {formErrors.Complémentaddress && <p>{formErrors.Complémentaddress}</p>}
                                <div className="doublebox">
                                <input
                                    type="text"
                                    id="zip"
                                    name="zip"
                                    placeholder="Code postal*"
                                    value={formValues.zip}
                                    onChange={handleInputChange}
                                />
                                

                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="Ville*"
                                    value={formValues.city}
                                    onChange={handleInputChange}
                                />
                                <input 
                                type="text"
                                id="city"
                                name="city"
                                placeholder="Ville*"
                                value={formValues.city}
                                onChange={handleInputChange}
                                hidden
                            />
                                
                                </div>
                                <div className="doublebox">
                                    {formErrors.zip?<p>{formErrors.zip}</p>:<div></div>}
                                    {formErrors.city ? <p>{formErrors.city}</p>:<div></div>}
                                </div>
                                <input
                                type="text"
                                id="province"
                                name="province"
                                placeholder="Province du Québec*"
                                disabled
                                />
                                 <input
                                type="number"
                                id="phone"
                                name="phone"
                                placeholder="phone*"
                                value={formValues.phone}
                                onChange={handleInputChange}
                                />
                                {formErrors.phone && <p>{formErrors.phone}</p>}
                                <div className="Buttons">
                                    <button type="submit" className="Save">Ajouter</button>
                                        <a href="#" onClick={emptyValue}className="Cancel">Annuler</a>
                                </div>
                                
                            </div>
                            </form>
                    </div>
                </div>
                
            </div>
        ))}
    </>
    );
};

export default ProfilAdresses;