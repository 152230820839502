import "./Profil.css";
import Sidebar from "../../Components/ProfilComponent/SidebarProfil";
import ProfilBoxes from "../../Components/ProfilComponent/ProfilBoxes";
import ProfilContent from "../../Components/ProfilComponent/ProfilContent";
function Profil({}) {
    return (
        <div className="ProfilPageContainer">
            <div>
                <Sidebar />
            </div>
            <div className="profil-container">
                <ProfilBoxes/>
                <ProfilContent />
            </div>
        </div>
        );
    };
export default Profil;