import HomeEventSection from "../../Components/HomeComponents/HomeEventSection";
import DesertSection from "../../Components/HomeComponents/DesertSection";
import ProductsSection from "../../Components/HomeComponents/ProductsSection";


import "./Home.css";


function Home({}) {
    return (
        <div classname="Home">
            <div>
                <HomeEventSection />
            </div>
            <div>
                <ProductsSection />
            </div>
            <div>
                <DesertSection />
            </div>
        </div>
        );
    };
export default Home;