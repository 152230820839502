import "./ProductsSection.css";
import ProductCarousel from "./ProductCarousel";


function ProductsSection ({}) {
    return (
        <div className="products-container">
            <div className="producth">
                <h1 className="h1product">Notre collection</h1>
                <h2 className="h2product">RABAIS</h2>
            </div>
            <ProductCarousel/>                    
        </div>
        )
    }
    export default ProductsSection;