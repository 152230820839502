import "./SidebarMarque.css";
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const SidebarPrice = ({handleChangePrix}) => {
    const [maxPrice, setMaxPrice] = useState(0);
    const location = useLocation();
    let variable=decodeURIComponent(location.pathname.replace('/Exposition/',''));
    const [value, setValue] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            try{
          const response = await axios.get('https://aroobee-api.azurewebsites.net/Product/GetMaxPriceByCategory?Category='.concat(variable),{
          headers: {"Access-Control-Allow-Origin": "*"}}
        ); // Replace with your API endpoint
        setMaxPrice(response.data);
        }catch(err){
         }
        };
    
        fetchData();
      }, []);
    const handleChange = (event) => {
        // Ensure correct event value parsing to number
        const parsedValue = (event.target.value);
        // Clamp the value to the PriceData range
        const clampedValue = Math.max(0, Math.min(maxPrice, parsedValue));
        setValue(parsedValue);
        handleChangePrix(parsedValue);
      };

    return (
        <div className="SidebarChanger">
            <div>
            <h2 className="sidebar-title color-title">PRIX</h2>
            </div>
                <div>
                 <label>
                 <span>{value} $</span>
                 <input name="Prix" type="range" min={0} max={maxPrice} value={value} onChange={handleChange} />
                 <span>{maxPrice} $</span>
                 </label>
                </div>
        </div>
        );
};

export default SidebarPrice;