import React, { useState, useEffect } from "react";
import { Heart, Plus, Minus, ShoppingBag, ShoppingBagOpen  } from "phosphor-react";
import axios from "axios";
import FullHeart from '../../resources/Icons/FullHeart_Orange.png';
import OrangeBasketIcon from '../../resources/Icons/Basket_orange.png';


import "./productComponent.css";
function ProductComponent({
  id,
  img,
  marque,
  title,
  prevPrice,
  newPrice,
  MaxQuantity,
  currentQuantity,
  isFavorite,
  productPriceLabel
}) {
  const [quantity, setQuantity] = useState(0);
  const [fave,setFave]=useState(0);
  const handleQuantityChange = (event) => {
    let parsed = parseInt(event.target.value);
    if (isNaN(parsed)) {
      // Check if parsed is NaN
      setQuantity(0);
    }
  };
  useEffect(() => {
    if (currentQuantity >= 0) {
      setQuantity(currentQuantity);
    }
    setFave(isFavorite)
  }, [currentQuantity,isFavorite]);
  function renameColumns(data, renameMap) {
    return data.map(item => {
      const newItem = {};
      for (const key in item) {
        const newKey = renameMap[key] || key; // Use original key if not mapped
        newItem[newKey] = item[key];
      }
      return newItem;
    });
  }
  async function sendAxiosPostRequest(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      // Handle successful response
      return response.data; // Access response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
      console.error('An error occurred. Please try again later.');
      }
      // Handle errors
      return null; // Or throw an error if needed
    }
  }
  function handleAddQteChange() {
    let cartList = JSON.parse(localStorage.getItem("cartItems")) || [];

    let myObject = {
      id: id,
      img: img,
      title: title,
      newPrice: newPrice,
      MaxQuantity: MaxQuantity,
    };

    let index = cartList.findIndex((obj) => obj.id === id);

    if (index === -1) {
      setQuantity(1);
      myObject.newQuantity = 1;
      cartList = [...cartList, myObject];

    } else {
      if(cartList[index].newQuantity + 1<=MaxQuantity){
        setQuantity(cartList[index].newQuantity + 1);
        myObject.newQuantity = cartList[index].newQuantity + 1;
        cartList[index] = myObject;
      }
      
    }
    let updatedCartItems=renameColumns(cartList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
      const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
      const DataToSend={
      "cartId": 0,
      "clientMail": "string",
      "subTotal": 0,
      "total": 0,
      "cartItems": updatedCartItems
  }
  if(Token){
      sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
      {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
      .then(response => {
      })}
    localStorage.setItem("cartItems", JSON.stringify(cartList));
  }
  
  function handleAddtoFave()
  {
    const tokken= sessionStorage.getItem("UserTokken")||false;
      let email= sessionStorage.getItem('userEmail')||false;
      if(tokken){
        const DataToSubmit={
          "productId": id,
          "clientEmail": email,
        }
        
      sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Product/AddProductToFavorite',DataToSubmit,{
        headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(tokken)}})
      .then(responseData => {
        setFave(true)

        // isFavorite!=isFavorite;
      })
      .catch(error => {
          // Handle errors
      });
      }
}
function handleDeletetoFave(){
  const tokken= sessionStorage.getItem("UserTokken")||false;
  let email= sessionStorage.getItem('userEmail')||false;

  if(tokken){
    const DataToSubmit={
      "productId": id,
      "clientEmail": email
    }
  sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Product/DeleteProductToFavorite',DataToSubmit,{headers: {"Access-Control-Allow-Origin": "*","Authorization":
"Bearer ".concat(tokken)}})
  .then(responseData => {
    setFave(false)

  })
  .catch(error => {
      // Handle errors
  });
  }
}

  function handleMinQteChange() {

    let cartList = JSON.parse(localStorage.getItem("cartItems")) || [];

    let index = cartList.findIndex((obj) => obj.id === id);


    if (index !== -1) {
      if (cartList[index].newQuantity > 1) {
        cartList[index].newQuantity--;
        setQuantity(cartList[index].newQuantity);
      } else {
        cartList[index].newQuantity--;
        let updatedCartItems=renameColumns(cartList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
        const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
        const DataToSend={
        "cartId": 0,
        "clientMail": "string",
        "subTotal": 0,
        "total": 0,
        "cartItems": updatedCartItems
    }
    if(Token){
        sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
        {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
        .then(response => {
          cartList = cartList.filter((obj) => obj.id !== id);
          setQuantity(0);
          localStorage.setItem("cartItems", JSON.stringify(cartList));

        })   }
      }

      let updatedCartItems=renameColumns(cartList,{ id: 'productId', newQuantity: 'quantity',img:'photos',title:'productName',productPrice:'newPrice'})
        const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;
        const DataToSend={
        "cartId": 0,
        "clientMail": "string",
        "subTotal": 0,
        "total": 0,
        "cartItems": updatedCartItems
    }
    if(Token){
        sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Cart/AddCart',DataToSend,
        {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
        .then(response => {
        })}
    }
    
  }

  return (
    <div className="product-card">
      <a href={"/produit/"+id}>
      <img src={img} alt={title} className="card-img" /></a>
      <div className="card-details">
      <a href={"/produit/"+id}>
        <div className="child-left">
          <p>{title}</p>
          <b className="card-title">{marque}</b>
          <div className="card-price">
            <div className="">
              {prevPrice === 0 ? (
                <span className="prevPrice">${newPrice.toFixed(2)}</span>
              ) : (
                <>
                  {" "}
                  <span className="newPrice">${newPrice.toFixed(2)}</span>{" "}
                  <del>${prevPrice.toFixed(2)}</del>
                </>
              )}
            </div>
          </div>
          <div className="priceLabel">{productPriceLabel}</div>
        </div>
        </a>
        <div className="child-right">
          <div className="card-actions">
          {fave?
            <button className="HeartButton" onClick={handleDeletetoFave}>
              <img className="FullHeart" src={FullHeart} alt="PackageIcon"/>
            </button>
            :<button className="FavButton HeartButton" onClick={handleAddtoFave}>
              <Heart size={50} />              
            </button>}
            {quantity > 0 ? (
              <div className="quantity-wrapper">
                <div>
                  <button className="HeartButton" onClick={handleAddQteChange}>
                    <Plus size={50} />
                  </button>
                </div>
                <div>
                  <input
                    id="quantity"
                    type="number"
                    min="0"
                    max={MaxQuantity}
                    value={quantity}
                    onChange={handleQuantityChange}
                    className="quantity-input HeartButton"
                    readOnly
                  />
                </div>
                <div>
                  <button className="HeartButton" onClick={handleMinQteChange}>
                    <Minus size={50} />
                  </button>
                </div>
              </div>
            ) : (
              <button className="HeartButton" onClick={handleAddQteChange}>
                <img className="OrangeBasketIcon"src={OrangeBasketIcon} alt="PackageIcon"/>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductComponent;