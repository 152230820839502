import "./SortProductsExposition.css";

const SortProductsExposition = ({buttonOn,setButtonOn, handleChangeSort }) => {
  const handleChange = (event) => {
    handleChangeSort(event.target.value);
  };
  const handleClick = () => {
    setButtonOn(!buttonOn)
  }
  return (
    <>
      <div className={buttonOn?" buttonhidden rec-container":"rec-container"}>
        <div><button onClick={handleClick}>Filtrer</button></div>
        <div className="sort-container">
          <div className="sort-title">
            Trier par : 
          </div>
          <div>
            <select name="listsort" onChange={handleChange} className="price-filter-dropdown">
            <option value="">Sélectionnez </option>
              <option value="asc">Prix Croissant</option>
              <option value="desc">Prix Décroissant</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default SortProductsExposition;