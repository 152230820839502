import React, { useState,useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import "./UserInfo.css";
function UserInfo() {
  async function sendAxiosPostRequest(url, data, config = {}) {
    try {
      const response = await axios.post(url, data, config);
      // Handle successful response
      return response.data; // Access response data
    } catch (error) {
      // Handle errors
      return null; // Or throw an error if needed
    }
  }
 const [showSecondAddress, setShowSecondAddress] = useState(false);
  const navigate=useNavigate();
  const [formValues, setFormValues] = useState({
    email: '',
    adresse1fname: '',
    adresse1lname: '',
    adresse1phone: '',
    addresses:[],
    address: '',
    adresse1id:'',
    adresse2id:'',
    Complémentaddress: '',
    zip: '',
    city: '',
    province: '',
    delivery: false,
    adresse2fname: '',
    adresse2lname: '',
    adresse2phone: '',
    address2: '',
    Complémentaddress2: '',
    zip2: '',
    city2: '',
    filled:false
  });  
  const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;

  useEffect(() => {
    const fetchData = async () => {
        try{
      const response = await axios.get('https://aroobee-api.azurewebsites.net/User/GetAllInfoOfUser',{
        headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}}
        ); 
        setFormValues({ ...formValues,filled:true,email:response.data.email,addresses:response.data.addresses});
    }catch(err){
      if(err.response.status===401){
        localStorage.setItem('error', JSON.stringify(err.response.status));
        sessionStorage.clear();
      }}
    };
    if(!formValues.filled){    fetchData();
    }


  }, [formValues]);

  const [formErrors, setFormErrors] = useState({});

  const fullname_REGEX = /^[a-zA-Z\- ]{2,30}$/;

  const handleInputChange = (e) => {
    if(e.target.name==="addresses"){
      if(e.target.value!==""){
        const values = e.target.value.split("-");
        setFormValues({ ...formValues,adresse1id:values[7],adresse1fname:values[0],adresse1lname:values[1],adresse1phone:values[2], address: values[3],Complémentaddress:values[4],   zip: values[5],city: values[6]
    });
      }else{
        setFormValues({ ...formValues,adresse1id:0,adresse1fname:"",adresse1lname:"",adresse1phone:"", address: "",Complémentaddress:"",   zip: "",city: ""})
      }
    }else if(e.target.name!=="addresses2"){
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
      // setFormValues({ ...formValues, addresses: e.target.value });
      
    }
    if(e.target.name==="addresses2"){
      if(e.target.value!==""){
        const values = e.target.value.split("-");
        setFormValues({ ...formValues,adresse2id:values[7],adresse2fname:values[0],adresse2lname:values[1],adresse2phone:values[2], address2: values[3],Complémentaddress2:values[4],   zip2: values[5],city2: values[6]

    });

      }else{
        setFormValues({ ...formValues,adresse2id:0,adresse2fname:"",adresse2lname:"",adresse2phone:"", address2: "",Complémentaddress2:"",   zip2: "",city2: ""})

    }
      }else if(e.target.name!=="addresses"){
        // setFormValues({ ...formValues, addresses: e.target.value });
        
      setFormValues({ ...formValues, [e.target.name]: e.target.value });

      }
  };

  const handleCheckboxChange = () => {
    setShowSecondAddress(!showSecondAddress);
    setFormValues({ ...formValues, delivery: !formValues.delivery });

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {};

    if (!fullname_REGEX.test(formValues.adresse1fname)) {
      newFormErrors.adresse1fname = 'Prénom invalide';
    }

    if (!fullname_REGEX.test(formValues.adresse1lname)) {
      newFormErrors.adresse1lname = 'Nom de famille invalide';
    }
    

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      newFormErrors.email = 'E-mail invalide';
    }

    if (!/^\d{10}$/.test(formValues.adresse1phone)) {
      newFormErrors.adresse1phone = 'Téléphone est requis';
    }
    
    if(/^\d+$/.test(formValues.address.replace(/\s|-/g, ""))){
      newFormErrors.address = "Adresse non valide";
    }else{
      if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,59}$/.test(formValues.address)) {
        newFormErrors.address = 'Adresse invalide';
      }
    }
    
    formValues.zip=formValues.zip.replace(/\s|-/g, "");
    if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6}$/.test(formValues.zip)) {
      newFormErrors.zip = 'Code postal est requis';
    }
    if(formValues.Complémentaddress){
      if(/^\d+$/.test(formValues.Complémentaddress.replace(/\s|-/g, ""))){
        newFormErrors.Complémentaddress = "Adresse non valide";
      }else{
        if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,49}$/.test(formValues.Complémentaddress)) {
          newFormErrors.Complémentaddress = 'Adresse invalide';
        }
      }
      
    }else{
      formValues.Complémentaddress="";
    }
    
    
    if (!/^[a-zA-Z0-9\\s,'-. \#éçèùàôêâû]{5,49}$/.test(formValues.city)) {
      newFormErrors.city = 'Ville est requise';
    }


    if (showSecondAddress) {
      if (!fullname_REGEX.test(formValues.adresse2fname)) {
        newFormErrors.adresse2fname = 'Prénom invalide';
      }
  
      if (!fullname_REGEX.test(formValues.adresse2lname)) {
        newFormErrors.adresse2lname = 'Nom de famille invalide';
      }
      if (!/^\d{10}$/.test(formValues.adresse2phone)) {
        newFormErrors.adresse2phone = 'Téléphone est requis';
      }
      if(/^\d+$/.test(formValues.address2.replace(/\s|-/g, ""))){
        newFormErrors.address2 = "Adresse non valide";
      }else{
        if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,59}$/.test(formValues.address2)) {
          newFormErrors.address2 = 'Adresse invalide';
        }
      }
      if(/^\d+$/.test(formValues.Complémentaddress2.replace(/\s|-/g, ""))){
        newFormErrors.Complémentaddress2 = "Adresse non valide";
      }else{
        if (!/^[a-zA-Z0-9\- ,éçèùàôêâû]{5,49}$/.test(formValues.Complémentaddress2)) {
          newFormErrors.Complémentaddress2 = 'Adresse invalide';
        }
      }
      
    
    if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6}$/.test(formValues.zip2)) {
      newFormErrors.zip2 = 'Code postal de livraison est requis';
    }

    if (!/^[a-zA-Z0-9\\s,'-. \#éçèùàôêâû]{5,49}$/.test(formValues.city2)) {
      newFormErrors.city2 = 'Ville de livraison est requise';
    }
    }else{
      formValues.adresse2fname=formValues.adresse1fname;
      formValues.adresse2phone=formValues.adresse1phone;
      formValues.adresse2lname=formValues.adresse1lname;
      formValues.Complémentaddress2=formValues.Complémentaddress;
      formValues.address2=formValues.address;
      formValues.adresse2id=formValues.adresse1id;
      formValues.zip2=formValues.zip;
      formValues.city2=formValues.city;
    }
    if(formValues.Complémentaddress2===''){
      formValues.Complémentaddress2=null
    }
    if(formValues.Complémentaddress===''){
      formValues.Complémentaddress=null
    }
    formValues.province="Québec";
    setFormErrors(newFormErrors)
    if (Object.keys(newFormErrors).length === 0) {
      const DataToSubmit={
        "cart": {
          "cartId": 0,
          "clientMail": "string",
          "subTotal": 0,
          "total": 0,
          "cartItems": [
            {
              "productId": 0,
              "productPrice": 0,
              "productName": "string",
              "photos": "string",
              "quantity": 0
            }
          ]
        },
        "pourboir": 0,
        "promo": "string",
        "deliver": {
          "addressId": formValues.adresse1id,
          "addressName": formValues.address,
          "additionalAddress": formValues.Complémentaddress,
          "zipCode": formValues.zip,
          "city": formValues.city,
          "province": "Québec",
          "clientId": 0,
          "livraison": true,
          "facturation": true,
          "firstName": formValues.adresse1fname,
          "lastName": formValues.adresse1lname,
          "phone": formValues.adresse1phone
        },
      "facturationAddress": {
        "addressId": formValues.adresse2id,
          "addressName": formValues.address2,
          "additionalAddress": formValues.Complémentaddress2,
          "zipCode": formValues.zip2,
          "city": formValues.city2,
          "province": "Québec",
          "clientId": 0,
          "livraison": true,
          "facturation": true,
          "firstName": formValues.adresse2fname,
          "lastName": formValues.adresse2lname,
          "phone": formValues.adresse2phone
        }
      }
      sendAxiosPostRequest('https://aroobee-api.azurewebsites.net/Order/UpdateOrderAddresses',DataToSubmit,
      {headers: {"Access-Control-Allow-Origin": "*","Authorization":"Bearer ".concat(Token)}})
      .then(response => {
      })
    }
    navigate("/Commande/paiement");

  };

  return (
    <form className="Inscription-container" onSubmit={handleSubmit}>
      <h1>Vérifier vos informations</h1>

      <div>
        
        <input
          type="email"
          id="email"
          name="email"
          placeholder="E-mail*"
          value={formValues.email}
          onChange={handleInputChange}
          readOnly
        />
        {formErrors.email && <p>{formErrors.email}</p>}

        
    
            <select id="addresses" name="addresses"
          onChange={handleInputChange}>
                <option value="" selected>Choisissez une addresses:</option>
                
                {formValues?.addresses?.map(address => (
  <option value={address.firstName+"-"+address.lastName+"-"+address.phone+"-"+address.addressName+"-"+address.additionalAddress+"-"+address.zipCode+"-"+address.city+"-"+address.addressId
}>{address.firstName+" "+address.lastName}</option>
))}            </select>
          <div className="doublebox">
          <input
            type="text"
            id="adresse1fname"
            name="adresse1fname"
            placeholder="Prénom*"
            value={formValues.adresse1fname}
            onChange={handleInputChange}
          />
          
          <input
            type="text"
            id="adresse1lname"
            name="adresse1lname"
            placeholder="Nom de famille*"
            value={formValues.adresse1lname}
            onChange={handleInputChange}
          />
          
        </div>
        <div className="doublebox">
        {formErrors.adresse1fname && <p>{formErrors.adresse1fname}</p>}
        {formErrors.adresse1lname && <p>{formErrors.adresse1lname}</p>}
        </div>
        <input
          type="number"
          id="adresse1id"
          name="adresse1id"
          placeholder="adresse1id"
          value={formValues.adresse1id}
          onChange={handleInputChange}
          hidden
        />
        <input
          type="tel"
          id="adresse1phone"
          name="adresse1phone"
          placeholder="Téléphone*"
          value={formValues.adresse1phone}
          onChange={handleInputChange}
        />
        {formErrors.adresse1phone && <p>{formErrors.adresse1phone}</p>}
        <input
          type="text"
          id="address"
          name="address"
          placeholder="Adresse*"
          value={formValues.address}
          onChange={handleInputChange}
        />
        {formErrors.address && <p>{formErrors.address}</p>}

        <input
          type="text"
          id="Complémentaddress"
          name="Complémentaddress"
          placeholder="Complément d'adresse"
          value={formValues.Complémentaddress}
          onChange={handleInputChange}
        />
        {formErrors.Complémentaddress && <p>{formErrors.Complémentaddress}</p>}
        <div className="doublebox">
          <input
            type="text"
            id="zip"
            name="zip"
            placeholder="Code postal*"
            value={formValues.zip}
            onChange={handleInputChange}
          />
          

          <input
            type="text"
            id="city"
            name="city"
            placeholder="Ville*"
            value={formValues.city}
            onChange={handleInputChange}
          />
          
          
        </div>
        <div className="doublebox">
            {formErrors.zip && <p>{formErrors.zip}</p>}
            {formErrors.city && <p>{formErrors.city}</p>}
          </div>
        <input
          type="text"
          id="province"
          name="province"
          placeholder="Province du Québec*"
          disabled
        />
        {formErrors.province && <p>{formErrors.province}</p>}

        <div>
          <input
            type="checkbox"
            id="delivery"
            name="delivery"
            checked={formValues.delivery}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="delivery"> Adresse différente pour la livraison</label>
        </div>
        {showSecondAddress && (
          <div className="secondaddress">
            <select id="addresses2" name="addresses2"
          onChange={handleInputChange}>
                <option value="" selected>Choisissez une addresses:</option>
                
                {formValues?.addresses?.map(address => (
  <option value={address.firstName+"-"+address.lastName+"-"+address.phone+"-"+address.addressName+"-"+address.additionalAddress+"-"+address.zipCode+"-"+address.city+"-"+address.addressId
}>{address.firstName+" "+address.lastName}</option>
))}            </select>
             <div className="doublebox">
          <input
            type="text"
            id="adresse2fname"
            name="adresse2fname"
            placeholder="Prénom*"
            value={formValues.adresse2fname}
            onChange={handleInputChange}
          />
          
          <input
            type="text"
            id="adresse2lname"
            name="adresse2lname"
            placeholder="Nom de famille*"
            value={formValues.adresse2lname}
            onChange={handleInputChange}
          />
          
        </div>
        <div className="doublebox">
        {formErrors.adresse2fname && <p>{formErrors.adresse2fname}</p>}
        {formErrors.adresse2lname && <p>{formErrors.adresse2lname}</p>}
        </div>
        <input
          type="number"
          id="adresse2id"
          name="adresse2id"
          placeholder="adresse2id"
          value={formValues.adresse2id}
          onChange={handleInputChange}
          hidden
        />
        <input
          type="tel"
          id="adresse2phone"
          name="adresse2phone"
          placeholder="Téléphone*"
          value={formValues.adresse2phone}
          onChange={handleInputChange}
        />
        {formErrors.adresse2phone && <p>{formErrors.adresse2phone}</p>}
            
            <input
              type="text"
              id="address2"
              name="address2"
              placeholder="Adresse*"
              value={formValues.address2}
              onChange={handleInputChange}
            />
            {formErrors.address2 && <p>{formErrors.address2}</p>}

            <input
              type="text"
              id="Complémentaddress2"
              name="Complémentaddress2"
              placeholder="Complément d'adresse*"
              value={formValues.Complémentaddress2}
              onChange={handleInputChange}
            />
            {formErrors.Complémentaddress2 && <p>{formErrors.Complémentaddress2}</p>}
            <div className="doublebox">
              <input
                type="text"
                id="zip2"
                name="zip2"
                placeholder="Code postal*"
                value={formValues.zip2}
                onChange={handleInputChange}
              />

              <input
                type="text"
                id="city2"
                name="city2"
                placeholder="Ville*"
                value={formValues.city2}
                onChange={handleInputChange}
              />
            </div>
            <div className="doublebox">
            {formErrors.zip2 && <p>{formErrors.zip2}</p>}
            {formErrors.city2 && <p>{formErrors.city2}</p>}
          </div>
          </div>
        )}
        <button type="submit" onClick={handleSubmit}>Confirmer</button>
      </div>
    </form>
  );
}

export default UserInfo;