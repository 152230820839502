import React, { useState,useEffect } from 'react';
import "./ErrorDisplay.css";

const ErrorDisplay = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedError = localStorage.getItem('error');
    if (storedError) {
      try {
        const parsedError = JSON.parse(storedError);
        setError(parsedError);
      } catch (error) {
      } finally {
        // Always remove the error from localStorage after retrieval
        localStorage.removeItem('error');
      }
      // Create timeout function outside useEffect
      const timeoutFunction = () =>{ 
        setError(null);
    }

    setTimeout(() => {
        timeoutFunction();
      }, 10000);

      // Return cleanup function referencing the timeout function variable
    }
  }, [error]);

  return (
    <div>
      {error===null?<></>: (
        <div className="error-message">
          <h2>Session ended</h2>
        </div>
      )}
    </div>
  );
};

export default ErrorDisplay;
