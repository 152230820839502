import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ProductsExposition from "../../Components/ExpositionCategorieComponents/ProductsExposition";
import Sidebar from "../../Components/ExpositionCategorieComponents/Sidebar";
import "./ExpositionCategorie.css";
import SortProductsExposition from "../../Components/ExpositionCategorieComponents/SortProductsExposition";
import axios from "axios";
import { TrendUp } from "phosphor-react";
function ExpositionCategorie({}) {
  const location = useLocation();
  const variable = decodeURIComponent(
    location.pathname.replace("/Exposition/", "")
  );
  const [products, setProducts] = useState([]);
  const [buttonOn, setButtonOn] = useState(window.innerWidth < 750 ? false : true);

  useEffect(() => {
    const fetchData = async () => {
      const Token=JSON.parse(sessionStorage.getItem('UserTokken')) || null;

      try {
        const response = await axios.get(
          "https://aroobee-api.azurewebsites.net/Product/GetAllProductsByCategory?Category=".concat(
            variable
          ),
          {
            headers: { "Access-Control-Allow-Origin": "*" ,"Authorization":"Bearer ".concat(Token)},
          }
        ); // Replace with your API endpoint
        setProducts(response.data);
      } catch (err) {
      }
    };

    fetchData();
    
  }, []);
  const [selectedPays, setSelectedPays] = useState([]);
  const [selectedMarque, setSelectedMarque] = useState([]);
  const [selectedPrix, setselectedPrix] = useState(0);
  const [selectedSort, setSelectedSort] = useState("");

  // ----------- chackbox Filtering -----------
  const handleChangeSort = (value) => {
    setSelectedSort(value);
  };
  // ----------- chackbox Filtering -----------
  const handleChangePays = (event) => {
    const isChecked = event.target.checked;
    const category = event.target.value;

    // Add to `selectedCategories` if checked, remove if unchecked
    setSelectedPays((prevCategories) => {
      if (isChecked) {
        return [...prevCategories, category];
      } else {
        return prevCategories.filter(
          (prevCategory) => prevCategory !== category
        );
      }
    });
  };
  const handleChangePrix = (value) => {
    setselectedPrix(value);
  };
  const handleChangeMarque = (event) => {
    const isChecked = event.target.checked;
    const category = event.target.value;

    // Add to `selectedCategories` if checked, remove if unchecked
    setSelectedMarque((prevCategories) => {
      if (isChecked) {
        return [...prevCategories, category];
      } else {
        return prevCategories.filter(
          (prevCategory) => prevCategory !== category
        );
      }
    });
  };


  let result = [];

  const setQuantities = () => {
    let cartstoredList = JSON.parse(localStorage.getItem("cartItems")) || [];
    cartstoredList.forEach((productCart) => {
      result?.forEach((product) => {
        if (product.productId === productCart.id) {
          product.productQuantity = productCart.newQuantity;
        } else if (!product.productQuantity) {
          product.productQuantity = 0;
        }
      });
    });
  };

  function filteredData(
    products,
    selectedPays,
    selectedMarque,
    selectedPrix,
    selectedSort
  ) {
    let filteredProducts = products;
    // Filter by selectedPays (if defined)
    if (selectedPays.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedPays.includes(product.productpays)
      );
    }

    // Filter by selectedMarque (if defined)
    if (selectedMarque.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        selectedMarque.includes(product.productMarque)
      );
    }

    // Filter by selectedPrix (if defined and valid)
    if (selectedPrix > 0) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productNewPrice >= selectedPrix
      );
    }
    if (selectedSort === "asc") {
      // Sort the products by productNewPrice in ascending order
      filteredProducts = filteredProducts.sort(
        (a, b) => a.productNewPrice - b.productNewPrice
      );
    } else if (selectedSort === "desc") {
      // Sort the products by productNewPrice in descending order
      filteredProducts = filteredProducts.sort(
        (a, b) => b.productNewPrice - a.productNewPrice
      );
    }
    setQuantities();
    return filteredProducts;
  }

  result = filteredData(
    products,
    selectedPays,
    selectedMarque,
    selectedPrix,
    selectedSort
  );

  setQuantities();

  return (
    <div className="bodyexposition">
      <Sidebar
        buttonOn={buttonOn}
        setButtonOn={setButtonOn}
        handleChangePays={handleChangePays}
        handleChangeMarque={handleChangeMarque}
        handleChangePrix={handleChangePrix}
      />
      <div className="prods-container">
        <SortProductsExposition handleChangeSort={handleChangeSort} buttonOn={buttonOn} setButtonOn={setButtonOn} />
        {result && <ProductsExposition buttonOn={buttonOn}result={result} />}
      </div>
    </div>
  );
}
export default ExpositionCategorie;